


import React from 'react'
import  BlogContent  from "./BlogContent";




 const BlogContentPage = ({blogs}) => {
console.log(blogs)


    return (
        <div>





            <BlogContent blogs={blogs} />

             


        </div>
    )
}
export default BlogContentPage;