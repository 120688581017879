import "./home.css";

import careerr3 from "../assets/Careers/career3.webp";
import hang from "../assets/Managed/hanging.webp";
import career2 from "../assets/Careers/career2.webp";
import React, { useState, useEffect } from "react";
import career1 from "../assets/Careers/career1.webp";
import { Helmet } from "react-helmet";

const Career = () => {
  const handleMailButtonClick = () => {
    window.location.href =
      "mailto:enquiry@randomwalk.ai?subject=Subject&body=Body";
  };
  const sentences = [
    "Fostering a vibrant AI community.",
    "Events that unite experts from diverse backgrounds.",
    "Ethical and responsible AI use.",
  ];

  const [index, setIndex] = useState(0);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(-1);

  useEffect(() => {
    const currentSentence = sentences[index];
    const letterCount = currentSentence?.length;
    let letterIndex = 0;

    const letterIntervalId = setInterval(() => {
      if (letterIndex < letterCount) {
        setCurrentLetterIndex(letterIndex);
        letterIndex++;
      } else {
        clearInterval(letterIntervalId);

        setTimeout(() => {
          setIndex((prevIndex) => (prevIndex + 1) % sentences?.length);
          setCurrentLetterIndex(-1);
        }, 2000);
      }
    }, 70);

    return () => clearInterval(letterIntervalId);
  }, [index]);
  return (
    <section className="Main2 font-display ">
      <Helmet>
        <title>Careers - Random Walk</title>
        <meta property="og:title" content="Careers - Random Walk" />
        <meta
          name="description"
          content="Join our fast growing team With a service designed to fuel creativity and make meetings fun, working at Random Walk as good as you think — may be even better. Our Values What we love we stand for everyday Ethical and responsible AI use Fostering a vibrant AI community Events that unite experts from diverse "
        />
        <meta
          property="og:description"
          content="Join our fast growing team With a service designed to fuel creativity and make meetings fun, working at Random Walk as good as you think — may be even better. Our Values What we love we stand for everyday Ethical and responsible AI use Fostering a vibrant AI community Events that unite experts from diverse  "
        />
        <link rel="canonical" href="https://randomwalk.ai/careers/" />
      </Helmet>

      <section className="max-lg:hidden flex justify-center items-center md:mt-20 md:pb-10 lg:mt-24">
        <img
          src={career1}
          alt="Career"
          className="md:w-8/12 lg:w-10/12 2xl:w-10/12"
        />

        <article className="absolute md:left-24 2xl:left-10 flex flex-col justify-center items-start">
          <h2 className="text-[#666666] lg:text-5xl md:text-2xl 2xl:text-6xl font-bold md:translate-y-2 lg:-translate-y-0 2xl:-translate-y-4 md:translate-x-0 2xl:translate-x-24">
            Join our fast
          </h2>

          <p className="text-[#666666] md:mb-5 lg:mb-0 md:text-xs lg:max-w-auto lg:text-sm md:translate-y-5 lg:translate-y-0 2xl:-translate-y-3 2xl:translate-x-24 mt-5">
            <span className="text-[#666666] lg:text-5xl md:text-2xl 2xl:text-6xl font-bold ">
              Growing Team <br />
            </span>

            <span className="text-[#666666] 2xl:text-base mt-6 block">
              With a product designed to fuel creativity and make meetings{" "}
              <br /> fun, working at Random Walk is as good as you think — maybe
              <br /> even better
            </span>
          </p>
        </article>
      </section>

      <section className="flex lg:hidden max-md:hidden  justify-center items-center md:mt-20 md:pb-10 lg:mt-24">
        <img
          src={career1}
          alt="Career"
          className="pl-5 md:w-9/12 lg:w-9/12 2xl:w-9/12"
        />

        <article className="absolute md:left-24  flex flex-col justify-center items-start">
          <h2 className="text-[#666666] lg:text-6xl md:text-2xl 2xl:text-6xl font-bold md:translate-y-2 lg:-translate-y-5 2xl:-translate-y-10 md:translate-x-0 2xl:translate-x-24">
            Join our fast
          </h2>

          <p className="text-[#666666] md:mb-5 lg:mb-0 md:text-xs lg:max-w-auto lg:text-sm md:translate-y-4 lg:translate-y-0 2xl:-translate-y-3 2xl:translate-x-24">
            <span className="text-[#666666] lg:text-6xl md:text-2xl 2xl:text-6xl font-bold ">
              Growing Team <br />
            </span>
            <p className="md:max-w-72">
              <span className=" 2xl:text-base text-[#666666]  ">
                With a product designed to fuel creativity and make meetings
                fun, working at Random Walk is as good as you think — maybe even
                better
              </span>
            </p>
          </p>
        </article>
      </section>

      <section className="flex md:hidden pt-28 lg:hidden flex-col justify-center items-center md:mt-20 md:pb-10 lg:mt-24">
        <article className="relative flex flex-col justify-center items-start md:left-24">
          <h2 className="text-[#666666] text-3xl pl-5 lg:text-6xl md:text-2xl 2xl:text-6xl font-bold md:translate-y-2 lg:-translate-y-5 2xl:-translate-y-10 md:translate-x-0 2xl:translate-x-24">
            Join our fast
          </h2>

          <article className="text-[#666666] md:mb-5 lg:mb-0 md:text-xs lg:max-w-auto pl-4 lg:text-sm md:translate-y-4 lg:translate-y-0 2xl:-translate-y-3 2xl:translate-x-24">
            <div className="text-[#666666] lg:text-6xl md:text-2xl 2xl:text-6xl text-3xl font-bold ">
              Growing Team <br />
            </div>
            <div className="md:max-w-72">
              <p className="text-xs pt-3 2xl:text-base text-[#666666] ">
                With a product designed to fuel creativity and make meetings
                fun, working at Random Walk is as good as you think — maybe even
                better
              </p>
            </div>
          </article>
        </article>

        <img
          src={career1}
          alt="Career"
          className="md:w-9/12 lg:w-9/12 2xl:w-9/12 mt-4 md:mt-0"
        />
      </section>

      <figure className="lg:mt-10">
        <img
          src={hang}
          alt="Carrer_img1"
          className="pt-5 md:pt-0 w-screen lg:w-full lg:h-32 absolute left-0 right-0 -ml-screen mr-screen"
        />
      </figure>

      <section className="flex md:hidden flex-col-reverse lg:flex-row lg:items-center 2xl:pb-20 md:mt-0 lg:mt-24">
        <article className="block lg:hidden translate-y-32 text-left w-full px-2">
          <div className="relative overflow-hidden flex justify-center items-center h-40 w-full text-center">
            {sentences.map((sentence, i) => (
              <header
                key={i}
                className={`absolute w-full text-[#666666]  py-10 text-center transition-opacity duration-1000 ease-in-out ${
                  i === index ? "opacity-100" : "opacity-0"
                }`}
              >
                {sentence.split("").map((char, charIndex) => (
                  <span
                    key={charIndex}
                    className={`inline-block lg:text-2xl text-sm text-nowrap font-bold ${
                      char === " " ? "mr-0" : ""
                    }`}
                    style={{
                      opacity: charIndex <= currentLetterIndex ? 1 : 0,
                      transition: "opacity 0.1s ease-in-out",
                      width: char === " " ? "0.3em" : "auto",
                    }}
                  >
                    {char}
                  </span>
                ))}
              </header>
            ))}
          </div>
        </article>

        <article className="relative mt-12 flex-1 flex items-start">
          <figure className="absolute md:left-0 w-5/12  md:w-9/12 lg:w-full lg:left-20 2xl:top-4 top-0">
            <img src={career2} alt="Our_values" />
          </figure>

          <header className="absolute pl-5 md:left-0 left-10 translate-y-10 md:translate-x-16 lg:-translate-y-0 md:-translate-y-2 lg:translate-x-10 2xl:-translate-y-16 lg:left-32 flex flex-col justify-center items-start">
            <h3 className="text-[#666666] text-2xl md:ml-0 md:mt-16 md:text-4xl lg:text-5xl lg:pt-0 2xl:pt-20 font-bold text-left">
              Our
              <br />
              Values
            </h3>

            <h2 className="text-[#6666666]  text-xs pt-5 md:ml-0 md:text-lg lg:text-base md:pt-8 lg:pt-10 2xl:pt-10 2xl:text-xl">
              What we love we stand for everyday
            </h2>
          </header>
        </article>

        <article className="hidden lg:block text-left w-7/12 pl-10 md:translate-y-20 lg:translate-y-28 lg:-translate-x-12 2xl:-translate-x-80">
          <div className="relative overflow-hidden flex justify-center items-center h-40 w-full text-center">
            {sentences.map((sentence, i) => (
              <p
                key={i}
                className={`absolute w-full text-[#666666] py-10 text-center transition-opacity duration-1000 ease-in-out ${
                  i === index ? "opacity-100" : "opacity-0"
                }`}
              >
                {sentence.split("").map((char, charIndex) => (
                  <span
                    key={charIndex}
                    className={`inline-block lg:text-2xl text-nowrap font-bold ${
                      char === " " ? "mr-0" : ""
                    }`}
                    style={{
                      opacity: charIndex <= currentLetterIndex ? 1 : 0,
                      transition: "opacity 0.1s ease-in-out",
                      width: char === " " ? "0.3em" : "auto",
                    }}
                  >
                    {char}
                  </span>
                ))}
              </p>
            ))}
          </div>
        </article>
      </section>

      <section className="flex  max-md:hidden 2xl:pb-20 items-center md:mt-0 lg:mt-24">
        <article className="relative flex-1 flex  items-start">
          <figure className="absolute md:left-0 md:w-9/12 lg:w-full lg:left-20 2xl:top-4 top-0">
            <img src={career2} alt="Our_values" />
          </figure>

          <div className="absolute pl-5 md:left-0 left-10 translate-y-10 md:translate-x-16 lg:-translate-y-0 md:-translate-y-2 lg:translate-x-0 2xl:-translate-y-16 lg:left-32 flex flex-col justify-center items-start">
            <h3 className="text-[#666666] text-2xl md:ml-0 md:mt-16  md:text-4xl lg:text-5xl lg:pt-0 2xl:pt-20 font-bold text-left">
              Our
              <br />
              Values
            </h3>

            <p className="text-[#666666]  text-xs pt-3 md:ml-0 md:text-lg lg:text-base md:pt-8 lg:pt-10 2xl:pt-10  2xl:text-xl ">
              What we love we stand for everyday
            </p>
          </div>
        </article>

        <article className="flex text-left    w-7/12 pl-10   md:translate-y-20 lg:translate-y-28 lg:-translate-x-12 2xl:-translate-x-80">
          <div className="relative overflow-hidden flex justify-center  items-center h-40 w-full text-center">
            {sentences.map((sentence, i) => (
              <header
                key={i}
                className={`absolute w-full text-[#666666]  py-10 text-center transition-opacity duration-1000 ease-in-out ${
                  i === index ? "opacity-100" : "opacity-0"
                }`}
              >
                {sentence.split("").map((char, charIndex) => (
                  <span
                    key={charIndex}
                    className={`inline-block lg:text-2xl text-nowrap font-bold ${
                      char === " " ? "mr-0" : ""
                    }`}
                    style={{
                      opacity: charIndex <= currentLetterIndex ? 1 : 0,
                      transition: "opacity 0.1s ease-in-out",
                      width: char === " " ? "0.3em" : "auto",
                    }}
                  >
                    {char}
                  </span>
                ))}
              </header>
            ))}
          </div>
        </article>
      </section>

      <section className="bg-gray-50 py-5 px-5 mt-32 pt-10  lg:mt-48 md:mt-60 lg:pb-10 ">
        <h2 className="text-[#666666]  text-2xl   lg:pb-2 lg:text-4xl lg:pt-5 font-bold text-center ">
          Our Team
        </h2>
        <p className="font-display text-[#666666]  text-center pt-2 md:pt-0 lg:text-base text-xs">
          We are a tight-knit team distributed all around the globe
        </p>
        <figure className="">
          <img
            src={careerr3}
            alt="Our_Team"
            className="  rounded-3xl  md:w-6/12 lg:w-7/12 md:mt-6  mx-auto p-3"
          />
        </figure>
      </section>

      <section className="bg-white text-black text-center font-display rounded mb-10">
        <h2 className=" lg:mt-5 text-[#666666] pt-10 text-3xl lg:font-display font-bold lg:text-4xl lg:pt-10">
          Open Roles
        </h2>

        <p className=" text-xs text-[#666666]   pt-3 lg:pt-2 font-display lg:text-sm">
          Help us empower the service led growth economy
        </p>
        <article className="flex justify-center">
          <a
            onClick={handleMailButtonClick}
            className=" text-[#666666]  bg-gradient-to-r from-[#efbcff] to-[#f9dae7]  mt-10 border-none border lg:text-xl font-display font-bold p-5 md:py-8 md:px-8 rounded-2xl"
          >
            enquiry@randomwalk.ai
          </a>
        </article>
      </section>
    </section>
  );
};
export default Career;
