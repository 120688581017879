import React from "react";
import styles from "../styles/HeroSection.module.css";
import logo from "../assets/logo.svg";
import brandImage from "../assets/brand2.webp";
import brandImageMob from "../assets/hero-banner-mobile.webp";
import { isMobile } from "../config.tsx";
import { Helmet } from "react-helmet";

const HeroSection = ({ onOpenLeadForm }) => {
  const renderOnMobile = isMobile();
  return (
    <>
      <Helmet>
        <title>BrandCut</title>
        <meta
          name="description"
          content="Sponsorship Analytics Platform powered by AI. Measure Brand impressions data in real time."
        />

       
        <meta property="og:title" content="BrandCut" />
        <meta
          property="og:description"
          content="Sponsorship Analytics Platform powered by AI. Measure Brand impressions data in real time."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://randomwalk.ai/brandcut/" />

      
        <link rel="canonical" href="https://randomwalk.ai/brandcut/" />
      </Helmet>

      <section className={styles.hero}>
        <img className={styles.resp} src={logo} alt="Brand Logo" />
        <div className={styles.content}>
          <img className={styles.logo} src={logo} alt="BrandCut Logo" />
          <h1>
            Measure Your Brand <br />
            Sponsorship Strategy with <br />{" "}
            <span style={{ color: "#394CA7", fontWeight: "bold" }}>
              AI Brand Detection
            </span>
          </h1>

          <button onClick={onOpenLeadForm} className={styles.button}>
            Give it a Try for FREE
          </button>
        </div>
        <div className={styles.image}>
          <img
            src={renderOnMobile ? brandImageMob : brandImage}
            alt="AI Brand Detection"
          />
        </div>
      </section>
    </>
  );
};

export default HeroSection;
