import { useParams, Link } from "react-router-dom";
import React, { useMemo } from "react";
import useFetch from "../hooks/useFetch.js";
import "./style_kms.css";

import { Helmet } from "react-helmet";
import recright from "../assets/Home/recright.webp";
import white from "../assets/Blogs/white.webp";
import rightarrow from "../assets/Blogs/rightarrow.webp";
import kms from "../assets/BlogCategory/kmsheader.webp";
import cv from "../assets/BlogCategory/cvheader.webp";
import ai from "../assets/BlogCategory/aiheader.webp";
import other from "../assets/BlogCategory/otherheader.webp";
import Popup from "../components/popup.js";

import Lottie from "react-lottie";
import loading from "../pages/loading.json";

function categoryToSlug(category) {
  return category
    .toLowerCase()
    .replace(/[^\w\s-]/g, "") // Remove non-word chars
    .replace(/[\s_-]+/g, "-") // Replace spaces and underscores with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading/trailing hyphens
}
function titleToSlug(title) {
  return title
    .toLowerCase()
    .replace(/[^\w\s-]/g, "") // Remove non-word chars
    .replace(/[\s_-]+/g, "-") // Replace spaces and underscores with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading/trailing hyphens
}

const isCardSmall = (index) => {
  const normalizedIndex = index % 9;
  return (
    normalizedIndex === 3 || normalizedIndex === 7 || normalizedIndex === 8
  );
};

const BlogCategory = () => {
  const {
    loading: blogsLoading,
    data: blogs,
    error: blogsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/blog/data.json`
  );

  // Lottie animation options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const categoryMetadata = {
    "KMS AND RAG": {
      title: "Master Knowledge Management & Generative AI | Randomwalk.ai",
      description:
        "Unlock the power of knowledge management with Generative AI and Retrieval-Augmented Generation (RAG). Learn how AI enhances data handling, boosts decision-making, and transforms your organization's information ecosystem.",
    },
    "AI Training and AI Readiness": {
      title: "AI Readiness & Training for Business Success | Randomwalk.ai",
      description:
        "Is your organization AI-ready? Explore our AI Readiness Index to assess and enhance your team's capabilities. Discover expert AI training resources to future-proof your business with Randomwalk.ai.",
    },
    "Computer Vision": {
      title: "Cutting-Edge Computer Vision Solutions | Randomwalk.ai",
      description:
        "Explore the transformative power of computer vision. Learn how AI can interpret and analyze images to drive innovation across industries. Stay ahead with the latest advancements and use cases in computer vision.",
    },
    Others: {
      title: "Explore AI Innovations & Emerging Trends | Randomwalk.ai",
      description:
        "Dive into a diverse range of AI topics, from new breakthroughs to industry applications. Stay informed about the latest AI innovations, case studies, and expert opinions across various fields.",
    },
  };

  const { category: slugCategory } = useParams();

  const groupedBlogs = useMemo(() => {
    const grouped = {};
    blogs?.forEach((blog) => {
      if (!grouped[blog.blogCategory]) {
        grouped[blog.blogCategory] = [];
      }
      grouped[blog.blogCategory].push(blog);
    });
    return grouped;
  }, [blogs]);

  const category = Object.keys(groupedBlogs).find(
    (cat) => categoryToSlug(cat) === slugCategory
  );

  // Filter blogs by category
  const filteredBlogs = blogs?.filter((blog) => blog.blogCategory === category);

  // Sort filtered blogs by date to get the latest blog
  const sortedBlogs = filteredBlogs?.sort(
    (a, b) => new Date(b.blogDate) - new Date(a.blogDate)
  );

  // Separate the latest blog from the rest of the blogs
  const latestBlog = sortedBlogs?.length > 0 ? sortedBlogs[0] : null;
  const otherBlogs = sortedBlogs?.slice(1);

  blogs?.forEach((blog) => {
    if (!groupedBlogs[blog.blogCategory]) {
      groupedBlogs[blog.blogCategory] = [];
    }
    groupedBlogs[blog.blogCategory].push(blog);
  });

  const renderKMSHeader = () => (
    <>
      <Helmet>
        <title>{categoryMetadata["KMS AND RAG"].title}</title>
        <meta
          name="description"
          content={categoryMetadata["KMS AND RAG"].description}
        />
        <meta
          property="og:title"
          content={categoryMetadata["KMS AND RAG"].title}
        />
        <meta
          property="og:description"
          content={categoryMetadata["KMS AND RAG"].description}
        />
        <link
          rel="canonical"
          href={`https://randomwalk.ai/blog/category/${slugCategory}/`}
        />
      </Helmet>
      <section className="flex flex-col lg:pb-10 2xl:py-12 pt-24  md:pt-24 lg:pt-28 2xl:pt-32 font-display justify-center items-center p-5 bg-gradient_background">
        <article className="flex  flex-col md:px-20 lg:px-0  space-x-32 items-start md:flex-row md:items-end md:justify-between">
          <article className="text-container ">
            <p className="text-white text-lg lg:text-xl mb-1">Blog</p>
            <span className="text-white text-xl md:text-3xl lg:text-3xl font-semibold block md:mb-2">
              KMS and RAG
            </span>
            <span className="text-white text-xs md:text-base  md:pb-5 lg:pb-0 lg:text-lg md:block max-w-[400px] lg:block hidden">
              Read blogs on KMS and RAG to gain insights on latest strategies,
              best practices, and innovations that drive efficient knowledge
              sharing to transform your organization's data management and
              decision-making processes.
            </span>
          </article>

          <figure className=" ">
            <img
              src={kms}
              alt="KMS and RAG"
              className="md:w-auto md:h-64 -translate-x-16 md:-translate-x-0 h-56 py-3 md:py-0 md:pb-10 lg:pb-0 lg:h-72  "
            />
          </figure>
        </article>

        <article className="  2xl:px-10 md:pt-5 pt-5 lg:pt-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:w-full  md:gap-3 lg:gap-16 w-auto gap-5 ">
          <Link
            to="/blog/"
            className="bg-white bg-opacity-25 bg-white/50  text-white text-xs rounded-2xl lg:text-base flex items-center justify-center  w-full"
          >
            Home Page
          </Link>
          {Object.keys(groupedBlogs)
            ?.filter((cat) => cat !== category)
            .map((cat) => (
              <Link
                key={cat}
                to={`/blog/category/${categoryToSlug(cat)}/`}
                className="bg-white bg-opacity-25 lg:text-nowrap bg-white/50 text-white text-xs rounded-2xl lg:text-base flex items-center justify-center py-4 px-5  md:px-6 md:py-4 lg:py-5 lg:px-5 2xl:py-5 2xl:px-2 w-full "
              >
                {cat}
              </Link>
            ))}
        </article>
      </section>
    </>
  );

  const renderComputerVisionHeader = () => (
    <>
      <Helmet>
        <title>{categoryMetadata["Computer Vision"].title}</title>
        <meta
          name="description"
          content={categoryMetadata["Computer Vision"].description}
        />
        <link
          rel="canonical"
          href={`https://randomwalk.ai/blog/category/${slugCategory}/`}
        />

        <meta
          property="og:title"
          content={categoryMetadata["Computer Vision"].title}
        />
        <meta
          property="og:description"
          content={categoryMetadata["Computer Vision"].description}
        />
      </Helmet>
      <section className="flex flex-col lg:pb-10 2xl:py-12 pt-24  md:pt-24 lg:pt-28 2xl:pt-32  font-display justify-center items-center p-5 bg-gradient_background2">
        <article className="flex flex-col md:px-20 lg:px-0  space-x-32 items-start md:flex-row md:items-end md:justify-between">
          <article className="text-container ">
            <p className="text-white text-xl lg:text-xl mb-1">Blog</p>
            <span className="text-white text-xl md:text-3xl lg:text-3xl font-semibold block md:mb-2">
              Computer Vision
            </span>
            <span className="text-white text-xs md:text-base  md:pb-5 lg:pb-0 lg:text-lg md:block max-w-[400px] lg:block hidden">
              Read blogs on computer vision to learn about the latest
              advancements and applications in visual AI services. Explore how
              deep learning, image recognition and object detection are
              enhancing automation and driving efficiency in various sectors
            </span>
          </article>

          <figure className=" ">
            <img
              src={cv}
              alt="Computer Vision"
              className="md:w-auto md:h-64 -translate-x-16 md:-translate-x-0 h-56 py-3 md:py-0 md:pb-10 lg:pb-0 lg:h-72  "
            />
          </figure>
        </article>

        <article className="  2xl:px-10 md:pt-1 pt-5 lg:pt-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:w-full lg:gap-16 md:gap-3 w-auto gap-5 ">
          <Link
            to="/blog/"
            className="bg-white bg-opacity-25 bg-white/50  text-white text-xs rounded-2xl lg:text-base flex items-center justify-center  w-full"
          >
            Home Page
          </Link>
          {Object.keys(groupedBlogs)
            ?.filter((cat) => cat !== category)
            .map((cat) => (
              <Link
                key={cat}
                to={`/blog/category/${categoryToSlug(cat)}/`}
                className="bg-white bg-opacity-25 lg:text-nowrap bg-white/50 text-white text-xs rounded-2xl lg:text-base flex items-center justify-center py-4 px-5 md:px-6 md:py-4  lg:py-5 lg:px-3 2xl:py-5 2xl:px-2 w-full "
              >
                {cat}
              </Link>
            ))}
        </article>
      </section>
    </>
  );

  const renderAITrainingHeader = () => (
    <>
      <Helmet>
        <title>{categoryMetadata["AI Training and AI Readiness"].title}</title>
        <meta
          name="description"
          content={categoryMetadata["AI Training and AI Readiness"].description}
        />
        <link
          rel="canonical"
          href={`https://randomwalk.ai/blog/category/${slugCategory}/`}
        />
        <meta
          property="og:title"
          content={categoryMetadata["AI Training and AI Readiness"].title}
        />
        <meta
          property="og:description"
          content={categoryMetadata["AI Training and AI Readiness"].description}
        />
      </Helmet>
      <section className="flex flex-col pt-24 lg:pb-10 2xl:py-12 md:pt-24 lg:pt-28 2xl:pt-32  font-display justify-center items-center p-5 bg-gradient_background">
        <article className="flex flex-col md:px-20 lg:px-0  space-x-32 items-start md:flex-row md:items-end md:justify-between">
          <article className="text-container ">
            <p className="text-white text-xl lg:text-xl mb-1">Blog</p>
            <span className="text-white text-xl md:text-3xl lg:text-3xl font-semibold block md:mb-2">
              AI Training and AI Readiness
            </span>
            <span className="text-white text-xs md:text-base  md:pb-5 lg:pb-0 lg:text-lg md:block max-w-[400px] lg:block hidden">
              Read blogs on AI training for insights into organizational AI
              readiness, executive training, and corporate AI training programs.
              Learn how these initiatives prepare leaders and equip your team
              with the latest AI skills.
            </span>
          </article>

          <figure className=" ">
            <img
              src={ai}
              alt="AI Training and AI Readiness "
              className="md:w-auto md:h-64 -translate-x-16 md:-translate-x-0 h-56 py-3 md:py-0 md:pb-10 lg:pb-0 lg:h-72  "
            />
          </figure>
        </article>

        <article className="  2xl:px-10 md:pt-4 pt-5 lg:pt-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:w-full lg:gap-16 w-auto gap-5 gap-x-8">
          <Link
            to="/blog/"
            className="bg-white bg-opacity-25 bg-white/50  text-white text-xs rounded-2xl lg:text-base flex items-center justify-center  w-full"
          >
            Home Page
          </Link>
          {Object.keys(groupedBlogs)
            ?.filter((cat) => cat !== category)
            .map((cat) => (
              <Link
                key={cat}
                to={`/blog/category/${categoryToSlug(cat)}/`}
                className="bg-white bg-opacity-25  bg-white/50 text-white text-xs rounded-2xl lg:text-base flex items-center justify-center py-4 px-8  md:px-6 md:py-4 lg:py-5 lg:px-8 2xl:py-5 2xl:px-2 w-full "
              >
                {cat}
              </Link>
            ))}
        </article>
      </section>
    </>
  );

  const renderOthersHeader = () => (
    <>
      <Helmet>
        <title>{categoryMetadata["Others"].title}</title>
        <meta
          name="description"
          content={categoryMetadata["Others"].description}
        />
        <link
          rel="canonical"
          href={`https://randomwalk.ai/blog/category/${slugCategory}/`}
        />
        <meta property="og:title" content={categoryMetadata["Others"].title} />
        <meta
          property="og:description"
          content={categoryMetadata["Others"].description}
        />
      </Helmet>
      <section className="flex flex-col lg:pb-10 2xl:py-12 md:pt-24 pt-20  lg:pt-28 2xl:pt-32  font-display justify-center items-center p-5 bg-gradient_background2">
        <article className="flex flex-col md:px-20 lg:px-0  space-x-32 items-start md:flex-row md:items-end md:justify-between">
          <article className="text-container ">
            <p className="text-white text-xl lg:text-xl mb-1">Blog</p>
            <span className="text-white text-xl md:text-3xl lg:text-3xl font-semibold block md:mb-2">
              Others
            </span>
            <span className="text-white text-xs  md:text-base  md:pb-5 lg:pb-0 lg:text-lg md:block max-w-[400px] lg:block hidden">
              Explore our blogs for a comprehensive look into the latest trends,
              innovations, and applications of AI across various industries.
              Stay informed on AI and its transformative impact on technology
            </span>
          </article>

          <figure className=" ">
            <img
              src={other}
              alt="Others"
              className="md:w-auto md:h-64 -translate-x-16 md:-translate-x-0 h-60 py-3 md:py-0 md:pb-10 lg:pb-0 lg:h-72  "
            />
          </figure>
        </article>

        <article className="  2xl:px-10 md:pt-5 pt-5 lg:pt-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:w-full lg:gap-16  md:gap-3 w-auto gap-5 ">
          <Link
            to="/blog/"
            className="bg-white bg-opacity-25 bg-white/50  text-white text-xs rounded-2xl lg:text-base flex items-center justify-center  w-full"
          >
            Home Page
          </Link>
          {Object.keys(groupedBlogs)
            ?.filter((cat) => cat !== category)
            .map((cat) => (
              <Link
                key={cat}
                to={`/blog/category/${categoryToSlug(cat)}/`}
                className="bg-white bg-opacity-25 lg:text-nowrap bg-white/50 text-white text-xs rounded-2xl lg:text-base flex items-center justify-center py-4 px-5 md:px-6 md:py-4 lg:py-5 lg:px-3 2xl:py-5 2xl:px-2 w-full "
              >
                {cat}
              </Link>
            ))}
        </article>
      </section>
    </>
  );

  const renderCategoryHeader = () => {
    switch (category) {
      case "KMS AND RAG":
        return renderKMSHeader();
      case "Computer Vision":
        return renderComputerVisionHeader();
      case "AI Training and AI Readiness":
        return renderAITrainingHeader();
      case "Others":
        return renderOthersHeader();
      default:
        return null;
    }
  };

  if (blogsLoading) {
    return (
      <div className="w-full flex justify-center items-center min-h-screen">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }
  if (blogsError) return <div>Error loading blogs!</div>;
  if (!blogs) return null;

  return (
    <>
      {renderCategoryHeader()}

      <section className="w-full p-5 flex justify-center">
        <section className="max-w-[1000px] mx-auto pb-10">
          {/* Latest Blog */}
          <section className="flex justify-center text-[#666666] items-center">
            <article className="mb-8 p-4 w-full">
              {latestBlog && (
                <Link
                  key={latestBlog.id}
                  to={`/blog/${titleToSlug(latestBlog.blogTitle)}/`}
                  className="block"
                >
                  <div className="bg-white rounded-lg overflow-hidden drop-shadow-xl w-full md:h-80 md:flex relative">
                    <img
                      className=" md:w-7/12 lg:w-auto  w-full h-auto md:h-full"
                      src={`${latestBlog.coverImg.url}`}
                      alt={latestBlog.blogTitle}
                    />
                    <div className="p-4 relative flex-grow">
                      <h3 className="text-lg font-display font-bold  my-5 md:my-0 lg:my-5">
                        {latestBlog.blogTitle}
                      </h3>
                      <div
                        className="text-[#666666] font-display text-sm my-5 overflow-hidden"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 7,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {latestBlog.blogDesc}
                      </div>
                    </div>
                    <img
                      src={rightarrow}
                      alt="Blog Arrow"
                      className="absolute bottom-3 right-2 w-4 h-4"
                    />
                  </div>
                </Link>
              )}
            </article>
          </section>

          {/* Other blogs */}
          <section className="category-container">
            <article className="card-grid">
              {otherBlogs.map((blog, index) => {
                const cardClass = isCardSmall(index)
                  ? "card-small"
                  : "card-big";

                return (
                  <div key={blog.id} className={cardClass}>
                    <Link
                      to={`/blog/${titleToSlug(blog.blogTitle)}/`}
                      className="bg-white rounded-xl overflow-hidden drop-shadow-xl h-[100%] flex flex-col relative"
                    >
                      <img
                        className="object-cover h-52"
                        src={`${blog.coverImg.url}`}
                        alt={blog.blogTitle}
                      />
                      {isCardSmall(index) ? (
                        <div className="absolute top-0 left-0 bg-black bg-opacity-50 text-white px-2 py-1 rounded-lg font-bold">
                          {blog.blogTitle}
                        </div>
                      ) : null}
                      {isCardSmall(index) ? null : (
                        <div className="p-4">
                          <h3 className="text-lg text-[#666666] font-display font-bold my-3">
                            {blog.blogTitle}
                          </h3>
                          <p
                            className="text-[#666666] font-display text-sm my-3 overflow-hidden"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 5,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {blog.blogDesc}
                          </p>
                        </div>
                      )}
                      <img
                        src={isCardSmall(index) ? white : rightarrow}
                        alt="Right Arrow"
                        className="absolute bottom-3 right-3 w-4 h-4"
                      />
                    </Link>
                  </div>
                );
              })}
            </article>
          </section>
        </section>
      </section>

      <section
        className="text-[#666666] min-h-[140px] md:min-h-[230px] relative bg-no-repeat bg-cover"
        style={{
          background: "linear-gradient(100deg , #3E59B7 90%, #A6BAFF 110%)",
        }}
      >
        <figure className="absolute bottom-0 right-0">
          <img
            src={recright}
            alt="Footer"
            className="lg:w-40 lg:h-auto w-24 h-auto md:w-40 md:h-full"
          />
        </figure>

        <section className="mt-20">
        <Popup utmContent={`blog/category/${category}`} />
        </section>
      </section>
    </>
  );
};
export default BlogCategory;
