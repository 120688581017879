import React from "react";
import styles from "../styles/SectionThree.module.css";
import v1 from "../assets/v1.svg";
import v2 from "../assets/v2.svg";
import v3 from "../assets/v3.svg";
import v4 from "../assets/v4.svg";

const SectionThree = () => {
  return (
    <section className={styles.section}>
      <div className={styles.card}>
        <div className={styles.grid}>
          <div className={styles.cardItem}>
            <img className={styles.cardImage2} src={v1} alt="Image 1" />
            <div className={styles.cardContent}>
              <h3 className={styles.cardHeading}>Optimizing Sponsorship ROI</h3>
              <p className={styles.cardText}>
                Measure logo visibility in sports events in order to maximize ROI
              </p>
            </div>
          </div>
          <div className={styles.cardItem}>
            <img className={styles.cardImage} src={v2} alt="Image 2" />
            <div className={styles.cardContent}>
              <h3 className={styles.cardHeading}>
                Monitoring Social Media Engagement
              </h3>
              <p className={styles.cardText}>
                Track brand exposure in social media videos for effective
                marketing
              </p>
            </div>
          </div>
          <div className={styles.cardItem}>
            <img className={styles.cardImage} src={v3} alt="Image 3" />
            <div className={styles.cardContent}>
              <h3 className={styles.cardHeading}>
                Analyzing Corporate Event Effectiveness
              </h3>
              <p className={styles.cardText}>
                Evaluate logo visibility in corporate events to refine marketing
                strategies
              </p>
            </div>
          </div>
          <div className={styles.cardItem}>
            <img className={styles.cardImage} src={v4} alt="Image 4" />
            <div className={styles.cardContent}>
              <h3 className={styles.cardHeading}>
                Ensuring Sponsorship Compliance
              </h3>
              <p className={styles.cardText}>
                Monitor logo visibility to ensure adherence to sponsorship
                agreements
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionThree;
