import React, { useState, useEffect, useRef } from "react";
import logo from "../assets/Navbar/rwlogo.webp";
import { NavLink, Link, useLocation } from "react-router-dom";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);
  const [eventsOpen, setEventsOpen] = useState(false);
  const [supportOpen, setSupportOpen] = useState(false);
  const location = useLocation();
  const navRef = useRef();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setActiveDropdown(null);
  };
  useEffect(() => {
    setActiveDropdown(null);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".menu-item")) {
        closeMenus();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (dropdown) => {
    if (activeDropdown === dropdown) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdown);
    }
  };
  const closeMenus = () => {
    setMenuOpen(false);
    setServicesOpen(false);
    setProductsOpen(false);
    setCompanyOpen(false);
    setResourcesOpen(false);
    setEventsOpen(false);
  };

  const toggleServices = () => {
    setServicesOpen(!servicesOpen);
    setProductsOpen(false);
    setCompanyOpen(false);
    setResourcesOpen(false);
    setEventsOpen(false);
    setSupportOpen(!false);
  };

  const toggleProducts = () => {
    setProductsOpen(!productsOpen);
    setServicesOpen(false);
    setCompanyOpen(false);
    setResourcesOpen(false);
    setEventsOpen(false);
    setSupportOpen(!false);
  };

  const toggleCompany = () => {
    setCompanyOpen(!companyOpen);
    setServicesOpen(false);
    setProductsOpen(false);
    setResourcesOpen(false);
    setEventsOpen(false);
    setSupportOpen(!false);
  };

  const toggleResources = () => {
    setResourcesOpen(!resourcesOpen);
    setServicesOpen(false);
    setProductsOpen(false);
    setCompanyOpen(false);
    setEventsOpen(false);
    setSupportOpen(!false);
  };

  const toggleEvents = () => {
    setEventsOpen(!eventsOpen);
    setServicesOpen(false);
    setProductsOpen(false);
    setCompanyOpen(false);
    setResourcesOpen(false);
    setSupportOpen(!false);
  };
  const toggleSupport = () => {
    setSupportOpen(!supportOpen);
    setServicesOpen(false);
    setProductsOpen(false);
    setCompanyOpen(false);
    setResourcesOpen(false);
    setEventsOpen(false);
  };
  return (
    <nav
      ref={navRef}
      className="flex flex-col text-[#666666] md:flex-row items-center justify-between font-display shadow-lg bg-white py-4 fixed top-0 left-0 right-0 z-50"
    >
      {/* Left: Logo */}
      <article className="flex items-center w-full md:w-auto md:mr-auto">
        <Link to="/" className="cursor-pointer">
          <img
            src={logo}
            alt="Random Walk logo"
            className="md:w-24 lg:w-36 2xl:w-40 w-32 ml-2 h-8 md:ml-2 lg:ml-5 md:mt-1"
          />
        </Link>
        {/* Hamburger Menu for Mobile */}
        <article
          className="md:hidden flex items-center mr-5 md:mr-0 ml-auto"
          onClick={toggleMenu}
        >
          <div className={`menu-icon ${menuOpen ? "open" : ""}`}>
            <div className="w-6 h-0.5 bg-gray-600 mb-1"></div>
            <div className="w-6 h-0.5 bg-gray-600 mb-1"></div>
            <div className="w-6 h-0.5 bg-gray-600"></div>
          </div>
        </article>
      </article>

      <div
        className={`md:flex flex-grow justify-center ${
          menuOpen ? "block" : "hidden"
        } md:items-center absolute md:static top-full left-0 right-0 bg-white md:bg-transparent md:top-auto`}
      >
        <div className="flex flex-col mb-5 md:mb-0 md:flex-row md:gap-4 lg:gap-20 2xl:gap-36 md:items-center md:justify-center lg:ml-20 ">
          <div
            className="hidden md:block menu-item relative items-center"
            onClick={toggleServices}
          >
            <a
              href="#"
              className="elementor-item text-base font-semibold  elementor-item-anchor"
            >
              Services<span className="arrow">&#9662;</span>
            </a>
            {servicesOpen && (
              <div className="absolute top-full left-0 bg-white z-10 mt-1 shadow-lg rounded-md">
                <div className="text-sm flex flex-col">
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/ai-integration/"
                      onClick={closeMenu}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      AI Integration
                    </NavLink>
                  </div>
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/ai-managed-services/"
                      onClick={closeMenu}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      AI Managed Services
                    </NavLink>
                  </div>
                  <div className="pl-3 pr-2 py-2 pb-4">
                    <NavLink
                      to="/ai-workshop-for-corporates/"
                      onClick={closeMenu}
                      className="text-nowrap block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      AI Workshop for Corporates
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="md:hidden block menu-item relative items-center"
            onClick={() => toggleDropdown("services")}
          >
            <a
              href="#"
              className="elementor-item text-base font-semibold  items-center pl-4 "
            >
              Services
              <span className="arrow ">&#9662;</span>
            </a>
            {/* Render dropdown only if activeDropdown is 'services' */}
            {activeDropdown === "services" && (
              <div className="absolute md:static top-full left-0 bg-white z-10 mt-1 shadow-lg rounded-md ml-10 ">
                <div className="text-sm flex flex-col ">
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/ai-integration/"
                      onClick={closeMenus}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      AI Integration
                    </NavLink>
                  </div>
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/ai-managed-services/"
                      onClick={closeMenus}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      AI Managed Services
                    </NavLink>
                  </div>
                  <div className="pl-3 pr-2 py-2 pb-4">
                    <NavLink
                      to="/ai-workshop-for-corporates/"
                      onClick={closeMenus}
                      className="text-nowrap block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      AI Workshop for Corporates
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Products Dropdown */}
          <div
            className="hidden md:block menu-item relative items-center"
            onClick={toggleProducts}
          >
            <a
              href="#"
              className="elementor-item text-base font-semibold elementor-item-anchor"
            >
              Products<span className="arrow">&#9662;</span>
            </a>
            {productsOpen && (
              <div className="absolute left-0 bg-white z-10 mt-1 shadow-lg rounded-md text-nowrap">
                <div className="text-sm flex flex-col">
                  <div className="px-4 py-2">
                    <NavLink
                      to="/ai-readiness-index/"
                      onClick={closeMenu}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      AI Readiness Index
                    </NavLink>
                  </div>
                  <div className="px-4 py-2">
                    <NavLink
                      to="/brandcut/"
                      onClick={closeMenu}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      BrandCut
                    </NavLink>
                  </div>
                  <div className="px-4 py-2">
                    <NavLink
                      to="/fortunecookie/"
                      onClick={closeMenu}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      AI Fortune Cookie
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="md:hidden menu-item relative items-center mt-2"
            onClick={() => toggleDropdown("products")}
          >
            <a
              href="#"
              className="elementor-item text-base font-semibold items-center pl-4 "
            >
              Products
              <span className="arrow ">&#9662;</span>
            </a>
            {/* Render dropdown only if activeDropdown is 'services' */}
            {activeDropdown === "products" && (
              <div className="absolute md:static top-full left-0 bg-white z-10 mt-1 shadow-lg rounded-md ml-10 ">
                <div className="text-sm flex flex-col ">
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/ai-readiness-index/"
                      onClick={closeMenus}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      AI Readiness Index
                    </NavLink>
                  </div>
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/brandcut/"
                      onClick={closeMenus}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      BrandCut
                    </NavLink>
                  </div>
                  <div className="pl-3 pr-2 py-2 pb-4">
                    <NavLink
                      to="/fortunecookie/"
                      onClick={closeMenus}
                      className="text-nowrap block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      AI Fortune Cookie
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Company Dropdown */}

          <div
            className="hidden md:block menu-item relative items-center"
            onClick={toggleCompany}
          >
            <a
              href="#"
              className="elementor-item text-base font-semibold elementor-item-anchor"
            >
              Company<span className="arrow">&#9662;</span>
            </a>
            {companyOpen && (
              <div className="absolute top-full left-0 bg-white z-10 mt-1 shadow-lg rounded-md text-nowrap">
                <div className="text-sm flex flex-col">
                  <div className="px-4 py-1">
                    <NavLink
                      to="/about-us/"
                      onClick={closeMenu}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      About Us
                    </NavLink>
                  </div>
                  <div className="px-4 py-1 pb-2">
                    <NavLink
                      to="/careers/"
                      onClick={closeMenu}
                      className="text-nowrap block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Careers
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="md:hidden menu-item relative items-center mt-2"
            onClick={() => toggleDropdown("company")}
          >
            <a
              href="#"
              className="elementor-item text-base font-semibold items-center pl-4 "
            >
              Company
              <span className="arrow ">&#9662;</span>
            </a>
            {/* Render dropdown only if activeDropdown is 'services' */}
            {activeDropdown === "company" && (
              <div className="absolute md:static top-full left-0 bg-white z-10 mt-1 shadow-lg rounded-md ml-10 ">
                <div className="text-sm flex flex-col ">
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/about-us/"
                      onClick={closeMenus}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      About Us
                    </NavLink>
                  </div>
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/careers/"
                      onClick={closeMenus}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Careers
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Resources Dropdown */}
          <div
            className="hidden md:block menu-item relative items-center"
            onClick={toggleResources}
          >
            <a
              href="#"
              className="elementor-item text-base font-semibold elementor-item-anchor"
            >
              Resources<span className="arrow">&#9662;</span>
            </a>
            {resourcesOpen && (
              <div className="absolute top-full left-0 bg-white z-10 mt-1 shadow-lg rounded-md w-fit">
                <div className="text-sm flex flex-col">
                  <div className="px-4 py-1">
                    <NavLink
                      to="/blog/"
                      onClick={closeMenu}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Blog
                    </NavLink>
                  </div>
                  <div className="px-4 py-1 pb-2">
                    <NavLink
                      to="/brochures/"
                      onClick={closeMenu}
                      className="text-nowrap block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Brochures
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="md:hidden menu-item relative items-center mt-2"
            onClick={() => toggleDropdown("resources")}
          >
            <a
              href="#"
              className="elementor-item text-base font-semibold items-center pl-4 "
            >
              Resources
              <span className="arrow ">&#9662;</span>
            </a>
            {/* Render dropdown only if activeDropdown is 'services' */}
            {activeDropdown === "resources" && (
              <div className="absolute md:static top-full left-0 bg-white z-10 mt-1 shadow-lg rounded-md ml-10 ">
                <div className="text-sm flex flex-col ">
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/blog/"
                      onClick={closeMenus}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Blog
                    </NavLink>
                  </div>
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/brochures/"
                      onClick={closeMenus}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Brochures
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Events Dropdown */}
          <div
            className="md:block hidden menu-item relative items-center"
            onClick={toggleEvents}
          >
            <a
              href="#"
              className="elementor-item text-base font-semibold elementor-item-anchor"
            >
              Events<span className="arrow">&#9662;</span>
            </a>
            {eventsOpen && (
              <div className="absolute top-full left-0 bg-white z-10 mt-1 shadow-lg rounded-md w-fit">
                <div className="text-sm flex flex-col">
                  <div className="px-4 py-1">
                    <NavLink
                      to="/podcasts/"
                      onClick={closeMenu}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Podcasts
                    </NavLink>
                  </div>
                  <div className="px-4 py-1 pb-2">
                    <NavLink
                      to="/webinars/"
                      onClick={closeMenu}
                      className="text-nowrap block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Webinars
                    </NavLink>
                  </div>
                  <div className="px-4 py-1 pb-2">
                    <NavLink
                      to="/events/"
                      onClick={closeMenu}
                      className="text-nowrap block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      PlayLab
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="md:hidden menu-item relative items-center mt-2"
            onClick={() => toggleDropdown("events")}
          >
            <a
              href="#"
              className="elementor-item text-base font-semibold items-center pl-4 "
            >
              Events
              <span className="arrow ">&#9662;</span>
            </a>
            {/* Render dropdown only if activeDropdown is 'services' */}
            {activeDropdown === "events" && (
              <div className="absolute md:static top-full left-0 bg-white z-10 mt-1 shadow-lg rounded-md ml-10 ">
                <div className="text-sm flex flex-col ">
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/podcasts/"
                      onClick={closeMenus}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Podcasts
                    </NavLink>
                  </div>
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/webinars/"
                      onClick={closeMenus}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Webinars
                    </NavLink>
                  </div>
                  <div className="pl-3 pr-2 py-2 pb-4">
                    <NavLink
                      to="/events/"
                      onClick={closeMenus}
                      className="text-nowrap block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      PlayLab
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="md:hidden menu-item relative items-center mt-2"
            onClick={() => toggleDropdown("support")}
          >
            <a
              href="#"
              className="elementor-item text-base font-semibold items-center pl-4 "
            >
              Support
              <span className="arrow ">&#9662;</span>
            </a>
            {/* Render dropdown only if activeDropdown is 'services' */}
            {activeDropdown === "support" && (
              <div className="absolute md:static top-full left-0 bg-white z-10 mt-1 shadow-lg rounded-md ml-10 ">
                <div className="text-sm flex flex-col ">
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/contact-us/"
                      onClick={closeMenus}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Contact
                    </NavLink>
                  </div>
                  <div className="pl-3 pr-2 py-2">
                    <NavLink
                      to="/privacy-policy/"
                      onClick={closeMenus}
                      className="block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Privacy Policy
                    </NavLink>
                  </div>
                  <div className="pl-3 pr-2 py-2 pb-4">
                    <NavLink
                      to="/terms-of-service/"
                      onClick={closeMenus}
                      className="text-nowrap block px-2 py-2 rounded hover:bg-[#B4BDFF] hover:text-white"
                    >
                      Terms of Service
                    </NavLink>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center hidden md:block mr-5">
            <button className="bg-white border border-blue-600 rounded">
              <NavLink
                to="/contact-us/"
                className="elementor-item text-base text-blue-600 font-semibold text-display py-2 px-6 rounded"
                onClick={closeMenu}
              >
                Contact
              </NavLink>
            </button>
          </div>
        </div>
      </div>

      
























    </nav>
  );
};
