import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import pii from "../assets/Blogs/pi.webp";
import rightarrow from "../assets/Blogs/rightarrow.webp";
import white from "../assets/Blogs/white.webp";
import Popup from "../components/popup.js";
import { Helmet } from "react-helmet";
import useFetch from "../hooks/useFetch.js";
import Lottie from "react-lottie";
import loading from "../pages/loading.json";

function titleToSlug(title) {
  return title
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

function categoryToSlug(category) {
  return category
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

const Blogs = () => {

  const {
    loading: blogsLoading,
    data: blogData,
    error: blogsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/blog/home.json`
  );
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);

 
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

 
  const groupedBlogs = blogData || {};


  const categoryOrder = [
    "KMS and RAG",
    "Computer Vision",
    "AI Training and AI Readiness",
    "Others",
  ];
  
  const sortedCategories = categoryOrder.filter(category => groupedBlogs[category]?.length > 0);


  if (blogsLoading) {
    return (
      <div className="w-full flex justify-center items-center min-h-screen">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }


  return (
    <div className="">
      <Helmet>
        <title>AI Insights & Innovation Blog | Randomwalk.ai</title>
        <meta
          property="og:title"
          content="AI Insights & Innovation Blog | Randomwalk.ai"
        />
        <meta
          name="description"
          content="Stay ahead with Randomwalk.ai's expert blog on AI, machine learning, and data-driven innovation. Explore trends, case studies, and insights to elevate your AI journey."
        />
        <meta
          property="og:description"
          content="Stay ahead with Randomwalk.ai's expert blog on AI, machine learning, and data-driven innovation. Explore trends, case studies, and insights to elevate your AI journey."
        />
        <link rel="canonical" href="https://randomwalk.ai/blog/" />
      </Helmet>

      <section className="flex  flex-col font-display justify-center items-start p-5 md:pt-56 md:pb-8 lg:pt-60 pt-72 bg-bloghome ">
        <article className="flex flex-col items-start md:flex-row md:items-end md:justify-between">
          <p className="md:pb-16  -translate-y-16 md:-translate-y-0 pb-5 lg:pb-28 md:pl-10 pl-1">
            <p className="text-[#666666] text-xs md:text-xs lg:text-xl 2xl:text-2xl font-medium mb-1 mt-0">
              Randomwalk Blogs
            </p>
            <span className="text-[#666666] text-2xl md:text-3xl lg:text-5xl 2xl:text-5xl font-semibold">
              All Of Our Thoughts, In
              <br />
            </span>
            <span className="text-[#666666] text-2xl md:text-3xl lg:text-5xl 2xl:text-5xl font-semibold  pb-10">
              One Place
            </span>
          </p>
        </article>

        <article className="lg:pb-4 grid grid-cols-2 md:px-0 2xl:px-10 lg:px-0 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:w-full md:gap-3  lg:gap-16 w-auto gap-4">
          {sortedCategories.map((category) => (
            <Link
              key={category}
              to={`/blog/category/${categoryToSlug(category)}/`}
              className=" border font-bold border-gray-700 text-xs 2xl:text-xl text-[#666666] rounded-2xl lg:text-nowrap px-5 py-4 md:px- md:py-4 lg:py-5 lg:px-0 2xl:py-5 z-10 lg:text-base flex items-center justify-center p-3 w-full"
            >
              {category}
            </Link>
          ))}
        </article>
      </section>

      {sortedCategories.map((category) => (
        <section
          key={category}
          className="lg:pt-10 text-[#666666] flex justify-center pb-10 "
        >
          <article className="flex px-5 flex-wrap justify-center">
            <div className="category-container w-full">
              <div className="flex items-center lg:mb-5 mb-1 mt-5">
                <Link
                  to={`/blog/category/${categoryToSlug(category)}/`}
                  className="flex items-center"
                >
                  <h2 className="text-sm text-[#666666] lg:text-xl mb-2 font-bold font-display">
                    {category}
                  </h2>
                  <img
                    src={pii}
                    alt="Category Arrow"
                    className="ml-2 h-4 w-4 mb-2"
                  />
                </Link>
              </div>

              <div className="card-grid text-[#666666]">
                {groupedBlogs[category]
                  ?.sort((a, b) => new Date(b.blogDate) - new Date(a.blogDate))

                  ?.slice(0, 4)
                  .map((blog, index) => {
                    let displayIndex = index;
                    if (category === "Computer Vision") {
                      if (index === 0) displayIndex = 0;
                      else if (index === 1) displayIndex = 2;
                      else if (index === 2) displayIndex = 1;
                      else if (index === 3) displayIndex = 3;
                    } else if (category === "AI Training and AI Readiness") {
                      if (index === 0) displayIndex = 2;
                      else if (index === 1) displayIndex = 1;
                      else if (index === 2) displayIndex = 0;
                      else if (index === 3) displayIndex = 3;
                    } else if (category === "Others") {
                      if (index === 0) displayIndex = 0;
                      else if (index === 1) displayIndex = 1;
                      else if (index === 2) displayIndex = 2;
                      else if (index === 3) displayIndex = 3;
                    }
                    return (
                      <BlogCard
                        key={blog.id}
                        blog={blog}
                        displayIndex={displayIndex}
                      />
                    );
                  })}
              </div>
            </div>
          </article>
        </section>
      ))}
    
      <section className="mt-20 ">
        <Popup utmContent={"blog"} />
      </section>
    </div>
  );
};

const BlogCard = ({ blog, displayIndex }) => {
  const titleRef = useRef();
  const [titleLines, setTitleLines] = useState(1);

  useEffect(() => {
    if (titleRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(titleRef.current).lineHeight
      );
      const titleHeight = titleRef.current.clientHeight;
      const lines = Math.ceil(titleHeight / lineHeight);
      setTitleLines(lines);
    }
  }, [titleRef.current]);

  const descriptionLines = 7 - titleLines; // Adjust this based on the requirement

  return (
    <section
      key={blog.id}
      className={`relative mb-0 ${
        displayIndex === 2 || displayIndex === 3 ? "card-small" : "card-big"
      }`}
    >
      <Link
        to={`/blog/${titleToSlug(blog.blogTitle)}/`}
        className="bg-white rounded-xl overflow-hidden drop-shadow-xl lg:drop-shadow-xl w-full max-w-[350px] h-[100%] flex flex-col relative"
      >
        <img
          className="object-cover h-56" // Set the height and width here
          src={`${blog.coverImg.url}`}
          alt={blog.blogTitle}
        />

        {(displayIndex === 2 || displayIndex === 3) && (
          <div className="absolute top-0 left-0 bg-black bg-opacity-45 rounded-2xl text-white p-2">
            <h3
              ref={titleRef}
              className="text-sm lg:text-sm font-display font-bold"
            >
              {blog.blogTitle}
            </h3>
          </div>
        )}
        {displayIndex !== 2 && displayIndex !== 3 && (
          <div className="p-4">
            <h3 ref={titleRef} className="text-lg font-display font-bold my-3">
              {blog.blogTitle}
            </h3>
            <p
              className="text-black font-display text-sm my-3 overflow-hidden"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: descriptionLines,
                WebkitBoxOrient: "vertical",
              }}
            >
              {blog.blogDesc}
            </p>
          </div>
        )}
        <img
          src={displayIndex === 2 || displayIndex === 3 ? white : rightarrow}
          alt="Blog Arrow"
          className="absolute bottom-4 right-4 w-4 h-4"
        />
      </Link>
    </section>
  );
};
export default Blogs;