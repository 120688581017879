import React, { useState, useEffect } from "react";
import { Client, Databases, ID } from "appwrite";
import styles from "../styles/LeadForm.module.css";
import close from "../assets/close.webp";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";

const LeadForm = ({ visible, onClose, onSubmit },{utmContent}) => {
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(true); // Move this outside the conditional
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmailId] = useState("");
  // const [utmSource, setUtmSource] = useState("");
  // const [utmMedium, setUtmMedium] = useState("");
  // const [utmCampaign, setUtmCampaign] = useState("");
  // const [utmId, setUtmId] = useState("");
  // const [utmTerm, setUtmTerm] = useState("");
  // const [utmContent, setUtmContent] = useState("");
  
  const [utmParams, setUtmParams] = useState({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_id: "",
    utm_term: "",
    utm_content: "",
  });
  const [errors, setErrors] = useState({});

  const client = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);
  const databases = new Databases(client);

  const handlePhoneNumberChange = (number, country) => {
    setPhoneNumber(number);

    try {
      // Ensure number starts with +
      const fullNumber = number.startsWith("+") ? number : `+${number}`;

      // Check if the number is valid for the selected country
      if (isValidPhoneNumber(fullNumber)) {
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
        const nationalNumber = phoneNumberObj.nationalNumber;

        // Get expected length based on country
        const expectedLength = phoneNumberObj.nationalNumber?.length;
        const actualLength = nationalNumber?.length;

        if (actualLength < expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too short - needs ${expectedLength} digits`,
          }));
        } else if (actualLength > expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too long - should be ${expectedLength} digits`,
          }));
        } else {
          // Clear the error when valid
          setErrors((prev) => ({
            ...prev,
            phoneNumber: "",
          }));
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
      }
    } catch (error) {
      console.error("Phone validation error:", error);
      setErrors((prev) => ({
        ...prev,
        phoneNumber: "Please enter a valid phone number",
      }));
    }
  };

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(window.location.search);
  //   setUtmSource(searchParams.get("utm_source") || "");
  //   setUtmMedium(searchParams.get("utm_medium") || "");
  //   setUtmCampaign(searchParams.get("utm_campaign") || "");
  //   setUtmId(searchParams.get("utm_id") || "");
  //   setUtmTerm(searchParams.get("utm_term") || "");
  //   setUtmContent(searchParams.get("utm_content") || "");
  // }, []);


  useEffect(() => {
    const captureUtmParams = () => {
      const params = new URLSearchParams(window.location.search);
      const utmFields = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_id",
        "utm_term",
        "utm_content",
      ];
  
      const newUtmParams = {      utm_content: utmContent,};
      utmFields.forEach((field) => {
        newUtmParams[field] = params.get(field) || "";
      });
      setUtmParams(newUtmParams);
    };
  
    captureUtmParams();
    window.addEventListener("popstate", captureUtmParams);
  
    return () => {
      window.removeEventListener("popstate", captureUtmParams);
    };
  }, []);






  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const newErrors = {};

    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    }

    if (Object.keys(newErrors)?.length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      const fullNumber = phoneNumber.startsWith("+")
        ? phoneNumber
        : `+${phoneNumber}`;
      if (!isValidPhoneNumber(fullNumber)) {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number", // Changed from phoneNumber to phonenumberint
        }));
        return;
      }
      const formData={
           name,
          designation,
          companyName,
          phoneNumber,
          email,
          utmSource: utmParams.utm_source,
        utmMedium: utmParams.utm_medium,
        utmCampaign: utmParams.utm_campaign,
        utmId: utmParams.utm_id,
        utmTerm: utmParams.utm_term,
        utmContent: "brandcut", // Set the utmContent to "homepage"

 }
      await Promise.all([
        databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID7,
        ID.unique(),
        formData
      ),
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-brandcut`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      }).then(async (response) => {
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to submit form');
        }
      })
    ]);
      onClose(); // Close the form
      setIsSuccessOpen(true); // Show success modal
      onSubmit();
      setIsLoading(false);
      resetForm();
    } catch (error) {
      console.error("Error creating document:", error);
    }
  };

  const resetForm = () => {
    setName("");
    setDesignation("");
    setCompanyName("");
    setPhoneNumber("");
    setEmailId("");
    setErrors({});
  };

  const closeForm = () => {
    resetForm(); // Clear form data
    onClose(); // Close the form modal
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };

  if (!visible && !isSuccessOpen) return null; // Keep this conditional, but ensure hooks are above

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div>
      {visible && (
        <div className={styles.formOverlay}>
          <div className={styles.formContainer}>
            <button className={styles.closeButton} onClick={closeForm}>
              <img className={styles.closeIcon} src={close} alt="Close" />
            </button>
            <h2 className="font-bold">
              Unlock Your Demo
              <br />
              <span className={styles.highlight}>in Just 15 Seconds!</span>
            </h2>
         
            <form onSubmit={handleSubmit}>
            <div className="max-h-[400px] overflow-y-auto custom-scrollbar pr-4 ">
              {/* Form Fields */}
              <div className="mb-2">
                <label className="text-gray-700">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>
              <div className="mb-2">
                <label className="text-gray-700">Email Address</label>
                <input
                  id="emailaddress"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmailId(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div className="mb-2">
                <label className="text-gray-700">Phone Number</label>
                <PhoneInput
                  id="phonenumber"
                  country={"in"}
                  value={phoneNumber}
                  onChange={(number, country) =>
                    handlePhoneNumberChange(number, country)
                  }
                  inputClass="custom-phone-input2"
                  buttonClass="border rounded"
                  dropdownClass="border rounded"
                  enableSearch={true}
                  inputProps={{
                    required: true,
                    autoFocus: false,
                  }}
                />
                {errors.phoneNumber && (
                  <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
                )}
              </div>
              <div className="mb-2">
                <label className="text-gray-700">Designation</label>
                <input
                  id="designation"
                  type="text"
                  name="designation"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                />
                {errors.designation && (
                  <p className="text-red-500 text-sm">{errors.designation}</p>
                )}
              </div>
              <div className="mb-2">
                <label className="text-gray-700">Company Name</label>
                <input
                  type="text"
                  id="companyname"
                  name="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                {errors.companyName && (
                  <p className="text-red-500 text-sm">{errors.companyName}</p>
                )}
              </div>
              <div className="disclaimerContainercontact">
                <label className="checkboxLabel">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="checkbox"
                  />
                  <span className="checkboxText">
                    I agree and authorize team to contact me and/or send
                    relevant information over Email, SMS & WhatsApp. This will
                    override the registry with DNC/NDNC.
                  </span>
                </label>
              </div>
              {errors.terms && (
                <p className="text-red-500 text-sm">{errors.terms}</p>
              )}
              <div className="linksContainer">
                <a href="/terms-of-service/" className="link">
                  Terms of Service
                </a>
                <a href="/privacy-policy/" className="link">
                  Privacy Policy
                </a>
              </div>
              </div>
              <div className="mb-4 mt-6">
  <button
    id="submit"
    type="submit"
    className={`w-full px-32 py-2 bg-[#C62B6D] text-white rounded transition-opacity ${
      !isChecked || isLoading ? "opacity-50 cursor-not-allowed" : "hover:opacity-90"
    }`}
    disabled={!isChecked || isLoading}
  >
    {isLoading ? (
      <div className="flex items-center justify-center space-x-2">
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        <span>Submitting...</span>
      </div>
    ) : (
      "Submit"
    )}
  </button>
</div>
            </form>
          </div>
        </div>
      )}

      {isSuccessOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closeSuccessModal}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
            <h2 className="text-2xl font-bold mb-4 text-black">Success!</h2>
            <p className="mb-4 text-black">
              Your request has been sent successfully.
            </p>
            <button
              id="close"
              className="px-4 py-2 bg-[#C62B6D] text-white rounded"
              onClick={closeSuccessModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadForm;
