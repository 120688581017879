import React from "react";
import brochurebg2 from "../assets/Brochures/brochurebg2.png";
import brochurebg3 from "../assets/Brochures/brochurebg3.png";
import Popup from "../components/popup.js";
import { Helmet } from "react-helmet";
import useFetch from "../hooks/useFetch.js";


import Lottie from 'react-lottie';
import loading from '../pages/loading.json';

const Brochures = () => {
  const {
    loading: brochuresLoading,
    data: brochures,
    error: brochuresError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/brochure/data.json`
  );


  // Lottie animation options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };



  console.log("Brochures Data:", brochures);

  // Sort brochures by brochureDate in descending order
  const sortedBrochures =
    brochures?.sort(
      (a, b) => new Date(b.brochureDate) - new Date(a.brochureDate)
    ) ?? [];

  const handleDownload = (pdfUrl, brochureTitle) => {
    console.log("Attempting to download:", pdfUrl);
    if (pdfUrl) {
      window.open(pdfUrl, "_blank");
    } else {
      console.error("No PDF URL available for:", brochureTitle);
      alert("Sorry, the PDF is not available for download.");
    }
  };

  if (brochuresLoading) {
    return (
      <div className="w-full flex justify-center items-center min-h-screen">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }

  if (brochuresError) return <div>Error loading brochures!</div>;
  if (!brochures) return null;

  return (
    <>
      <Helmet>
        <title>Brochures - Random Walk</title>
        <meta property="og:title" content="Brochures - Random Walk" />
        <meta
          name="description"
          content="Brochures AI Mastery for Healthcare Unleashing Potential & Inspiring Care Download Executive AI Training Program Harnessing AI for Business Growth Download Maximize your business potential with seamless AI Integration. Download Your Random Walk towards AI begins now Contact Us"
        />
        <meta
          property="og:description"
          content="Brochures AI Mastery for Healthcare Unleashing Potential & Inspiring Care Download Executive AI Training Program Harnessing AI for Business Growth Download Maximize your business potential with seamless AI Integration. Download Your Random Walk towards AI begins now Contact Us"
        />
        <link rel="canonical" href="https://randomwalk.ai/brochures/" />
      </Helmet>

      <div className="md:hidden bg-gradient_background3 h-[28rem] md:h-[30rem] 2xl:h-[35rem]  bg-no-repeat bg-cover flex flex-col lg:flex-row lg:mt-16 justify-center items-center relative lg:w-auto">
        <div className="absolute  top-0 left-0 w-full h-full flex items-center justify-center">
          <img
            src={brochurebg2}
            alt="Description of image"
            className="h-full w-full object-cover"
          />
          <div className="absolute pt-32 -top-5  w-full h-full flex flex-col items-center">
            {/* Text on top */}
            <p className="text-white font-display text-3xl lg:text-5xl font-bold">
              Brochures
            </p>
            {/* Brochures container */}
            <div className="  2xl:scale-125 mx-auto pt-8">
              <div className="flex flex-row gap-0 px-6  text-black lg:ml-5 lg:mb-10">
                {sortedBrochures?.slice(0, 3).map(
                  (
                    brochure,
                    index // Display only the first 3 brochures
                  ) => (
                    <div
                      key={brochure.id}
                      className=" overflow-hidden drop-shadow-xl flex"
                      style={{ zIndex: index === 1 ? 2 : 1 }}
                    >
                      {index === 0 && ( // Apply position style to the 1st image
                        <img
                          className="w-48 h-48 md:h-52 md:w-40 md:mt-5 lg:h-64 lg:w-48  mt-5 -right-3 md:-right-10 relative lg:-right-5"
                          src={`${brochure.coverImgs?.url}`}
                          alt="Brochure Cover"
                        />
                      )}
                      {index === 1 && ( // Apply position style to the 2nd image
                        <img
                          className="w-56 h-56 md:h-60 md:w-40 lg:h-72 lg:w-48  "
                          src={`${brochure.coverImgs?.url}`}
                          alt="Brochure Cover"
                        />
                      )}
                      {index === 2 && ( // Apply position style to the 3rd image
                        <img
                          className="w-48 h-48 md:h-52 md:w-40 md:mt-5 lg:h-64 lg:w-48  mt-5  -left-3 relative md:-left-5 lg:-left-5"
                          src={`${brochure.coverImgs?.url}`}
                          alt="Brochure Cover"
                        />
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" max-md:hidden bg-gradient_background3 md:h-[30rem] 2xl:h-[35rem] flex flex-col lg:flex-row lg:mt-16 justify-center items-center relative lg:w-auto">
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <img
            src={brochurebg2}
            alt="Description of image"
            className="h-full w-full object-cover"
          />
          <div className="absolute lg:-top-5 md:-top-3 w-full h-full flex items-center md:justify-left ">
            <div className="text-white md:pl-20 lg:pl-32 2xl:text-6xl font-display md:text-4xl lg:text-5xl font-bold">
              Brochures
            </div>
            <div className="  2xl:scale-125 mx-auto pt-16">
              <div className="flex flex-row gap-0 px-4  text-black lg:ml-5 lg:mb-10">
                {sortedBrochures?.slice(0, 3).map(
                  (
                    brochure,
                    index // Display only the first 3 brochures
                  ) => (
                    <div
                      key={brochure.id}
                      className=" overflow-hidden drop-shadow-xl flex"
                      style={{ zIndex: index === 1 ? 2 : 1 }}
                    >
                      {index === 0 && ( // Apply position style to the 1st image
                        <img
                          className="md:h-52 md:w-40 md:mt-5 lg:h-64 lg:w-48  md:-right-10 relative lg:-right-5"
                          src={`${brochure.coverImgs?.url}`}
                          alt="Brochure Cover"
                        />
                      )}
                      {index === 1 && ( // Apply position style to the 2nd image
                        <img
                          className=" md:h-60 md:w-40 lg:h-72 lg:w-48  "
                          src={`${brochure.coverImgs?.url}`}
                          alt="Brochure Cover"
                        />
                      )}
                      {index === 2 && ( // Apply position style to the 3rd image
                        <img
                          className="md:h-52 md:w-40 md:mt-5 lg:h-64 lg:w-48   relative md:-left-5 lg:-left-5"
                          src={`${brochure.coverImgs?.url}`}
                          alt="Brochure Cover"
                        />
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full pb-20 py-[50px] px-6 lg:px-36"
        style={{ backgroundImage: `url(${brochurebg3})` }}
      >
        <div className="mx-auto pt-10">
          <div className="flex flex-col gap-10 lg:gap-16 px-4 text-black">
            {sortedBrochures.map((brochure) => {
              console.log("Brochure data:", brochure);

              // Accessing the PDF URL from the media field 'pdf_upload'
              const pdfUrl =
                brochure.pdf_upload && brochure.pdf_upload[0]
                  ? brochure.pdf_upload[0].url
                  : null;
              console.log("PDF URL:", pdfUrl);

              return (
                <div key={brochure.id} className="relative">
                  <div className="p-5 2xl:p-10 z-10 rounded-3xl overflow-hidden bg-white drop-shadow-xl flex flex-row items-center relative">
                    <div className="flex flex-row items-center justify-start">
                      {/* Check if coverImgs exist and render */}
                      {brochure.coverImgs?.url && (
                        <img
                          className="w-40 h-40 md:h-56 md:w-56 lg:h-56 lg:w-56 object-cover  rounded-xl mr-5"
                          src={`${brochure.coverImgs.url}`}
                          alt="Brochure Cover"
                        />
                      )}

                      <div className="flex md:ml-10 flex-col justify-center items-start">
                        <h3 className="font-semibold text-sm md:text-xl lg:text-2xl 2xl:text-3xl text-left mb-4 md:mb-8 my-1 text-[#666666] font-display">
                          {brochure.brochureTitle || "No Title Available"}
                        </h3>

                        {/* Download button */}
                        <button
                          onClick={() =>
                            handleDownload(pdfUrl, brochure.brochureTitle)
                          }
                          className="font-display bg-[#C62B6D] text-xs md:text-base text-white hover:text-white border-none font-bold py-2 px-4 md:py-4 md:px-16 rounded-xl"
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="mt-10">
        <Popup utmContent={"brochures"} />
      </div>
    </>
  );
};
export default Brochures;
