import React, { useState, useEffect, useRef } from "react";
import { Client, Databases, ID } from "appwrite";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import recright from "../assets/Home/recright.webp";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";

export const Popup = ({utmContent}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessemail, setEmailId] = useState("");
  const [projectdetails, setprojectdetails] = useState("");

  // const [utmSource, setUtmSource] = useState("");
  // const [utmMedium, setUtmMedium] = useState("");
  // const [utmCampaign, setUtmCampaign] = useState("");
  // const [utmId, setUtmId] = useState("");
  // const [utmTerm, setUtmTerm] = useState("");
  // const [utmContent, setUtmContent] = useState("");

  const [utmParams, setUtmParams] = useState({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_id: "",
    utm_term: "",
    utm_content: "",
  });
  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setfirstname("");
    setlastname("");
    setPhoneNumber("");
    setCompanyName("");
    setEmailId("");
    setprojectdetails("");
    setIsChecked(true);
    setErrors({});
  };
  const handleButtonClickcontact = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const client = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (number, country) => {
    setPhoneNumber(number);

    try {
      // Ensure number starts with +
      const fullNumber = number.startsWith("+") ? number : `+${number}`;

      // Check if the number is valid for the selected country
      if (isValidPhoneNumber(fullNumber)) {
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
        const nationalNumber = phoneNumberObj.nationalNumber;

        // Get expected length based on country
        const expectedLength = phoneNumberObj.nationalNumber?.length;
        const actualLength = nationalNumber?.length;

        if (actualLength < expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too short - needs ${expectedLength} digits`,
          }));
        } else if (actualLength > expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too long - should be ${expectedLength} digits`,
          }));
        } else {
          setErrors((prev) => ({ ...prev, phoneNumber: "" }));
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
      }
    } catch (error) {
      console.error("Phone validation error:", error);
      setErrors((prev) => ({
        ...prev,
        phoneNumber: "Please enter a valid phone number",
      }));
    }
  };

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(window.location.search);

  //   setUtmSource(searchParams.get("utm_source") || "");
  //   setUtmMedium(searchParams.get("utm_medium") || "");
  //   setUtmCampaign(searchParams.get("utm_campaign") || "");
  //   setUtmId(searchParams.get("utm_id") || "");
  //   setUtmTerm(searchParams.get("utm_term") || "");
  //   setUtmContent(searchParams.get("utm_content") || "");
  // }, []);


  useEffect(() => {
    const captureUtmParams = () => {
      console.log(utmContent);
      const params = new URLSearchParams(window.location.search);
      const utmFields = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_id",
        "utm_term",
      ];

      const newUtmParams = {
        utm_content: utmContent,
      };
      utmFields.forEach((field) => {
      
        newUtmParams[field] = params.get(field) || "";
      });
      console.log(newUtmParams);
      setUtmParams(newUtmParams);
    };

    captureUtmParams();
    window.addEventListener("popstate", captureUtmParams);

    return () => {
      window.removeEventListener("popstate", captureUtmParams);
    };
  }, [utmContent]);









  

  // const handleSubmit = async (event) => {
  //   event.preventDefault(); 
  //   setIsLoading(true); 
  //   try {
  //      // Validate phone number first
  //   if (!phoneNumber) {
  //     setErrors({ phoneNumber: "Phone number is required" });
  //     setIsLoading(false);
  //     return;
  //   }
  //     const fullNumber = phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`;
  //     if (!isValidPhoneNumber(fullNumber)) {
  //       setErrors((prev) => ({
  //         ...prev,
  //         phoneNumber: "Please enter a valid phone number",
  //       }));
  //       return;
  //     }
  // const formData={
  //   firstname,
  //   lastname,
  //   businessemail,
  //   companyName,
  //   phoneNumber,
  //   projectdetails,
  //   utmSource,
  //   utmMedium,
  //   utmCampaign,
  //   utmId,
  //   utmTerm,
  //   utmContent,

  // }
  //     // First, save to Appwrite
  //     await Promise.all([

  //     databases.createDocument(
  //       process.env.REACT_APP_DATABASE_ID,
  //       process.env.REACT_APP_COLLECTION_ID2,
  //       ID.unique(),
  //       formData
 
  //     ),
  
  //     // Then send to Express backend
  //     fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-form`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(formData),
  //     }).then(async (response) => {
  
  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       throw new Error(errorData.message || 'Failed to submit form');
  //     }
  //   })
  // ]);
  
  //     // Close the modal and show success message
  //     setIsModalOpen(false);
  //     setIsSuccessOpen(true);
  //     resetForm();
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //     setErrors({ submit: "Failed to submit form. Please try again." });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };





  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
  
    try {
      // Validate phone number first
      if (!phoneNumber) {
        setErrors({ phoneNumber: "Phone number is required" });
        setIsLoading(false);
        return;
      }
  
      const fullNumber = phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`;
  
      if (!isValidPhoneNumber(fullNumber)) {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
        setIsLoading(false);
        return;
      }

  
      const formData = {
        firstname,
        lastname,
        businessemail,
        companyName,
        phoneNumber,
        projectdetails,
        utmSource: utmParams.utm_source,
        utmMedium: utmParams.utm_medium,
        utmCampaign: utmParams.utm_campaign,
        utmId: utmParams.utm_id,
        utmTerm: utmParams.utm_term,
        utmContent: utmParams.utm_content,
      };
  
      // First, save to Appwrite
      await Promise.all([
        databases.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_ID2,
          ID.unique(),
          formData
        ),
        // Then send to Express backend
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-form`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }).then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to submit form');
          }
        }),
      ]);
  

      // Close the modal and show success message
      setIsModalOpen(false);
      setIsSuccessOpen(true);
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrors({ submit: "Failed to submit form. Please try again." });
    } finally {
      setIsLoading(false);
    }
  };







  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };
  const signupRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === "#signup") {
      signupRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  return (
    <>
      <section>
        <section
          className="text-[#666666] min-h-[140px] md:min-h-[230px] relative -mt-20 bg-no-repeat bg-cover"
          style={{
            background: "linear-gradient(100deg , #3E59B7 90%, #A6BAFF 110%)", // Example pink gradient
          }}
        >
          <figure className="absolute bottom-0 right-0">
            <img
              src={recright}
              alt="Additional"
              className="lg:w-40 lg:h-auto w-24 h-36 md:w-40 md:h-full"
            />
          </figure>
          <article className="absolute  inset-0 flex flex-col justify-center items-center text-center text-white pt-4 md:pt-10 md:mr-32 lg:pt-5 md:ml-20">
            <h2 className="md:text-xl lg:text-2xl text-sm font-bold mb-4 font-display">
              Your Random Walk Towards AI Begins Now
            </h2>

            <button
              className="md:mt-3 mb-3 rounded-xl border text-white border-white md:py-3 md:px-20 px-8 py-2  transition-all duration-300 ease-in-out bg-transparent hover:bg-white hover:text-[#777676]"

              onClick={handleButtonClickcontact}
            >
              <div className="flex items-center ">
                <span className="text-xs md:text-lg font-bold">Contact</span>
              </div>
            </button>
          </article>
        </section>
        {isModalOpen && (
          <section className="fixed z-50 inset-0 md:p-0 lg:px-20 p-12  bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-[#F1F6FF]   p-8 lg:px-10 rounded-lg max-h-screen   max-w-md w-full">
              <a
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeModal}
              >
                <svg
                  className="lg:w-6 lg:h-6 w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </a>
              {/* <div className='text-lg md:text-xl text-center font-bold mb-4'>
                  Experience the Power of Data <br />
                  With
                  <span className='text-[#C62B6D] font-bold'> AI Fortune </span>
                  Cookie
                </div> */}
              <form onSubmit={handleSubmit}>
              <div className="max-h-[400px] overflow-y-auto custom-scrollbar pr-4 ">
                <div className="mb-2">
                  <label className=" text-gray-700">First Name</label>
                  <input
                    id="firstname"
                    type="text"
                    name="firstname"
                    value={firstname}
                    onChange={(e) => setfirstname(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                  {errors.firstname && (
                    <p className="text-red-500 text-sm">{errors.firstname}</p>
                  )}
                </div>
                <div className="mb-2">
                  <label className=" text-gray-700">Last Name</label>
                  <input
                    type="text"
                    name="lastname"
                    id="lastname3"
                    value={lastname}
                    onChange={(e) => setlastname(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                  {errors.lastname && (
                    <p className="text-red-500 text-sm">{errors.lastname}</p>
                  )}
                </div>
                <div className="mb-2">
                  <label id="Phonenumber" className=" text-gray-700">
                    Phone Number
                  </label>
                  <PhoneInput
                    country={"in"}
                    value={phoneNumber}
                    onChange={(number, country) =>
                      handlePhoneNumberChange(number, country)
                    }
                    inputClass="custom-phone-input2 "
                    buttonClass="border rounded"
                    dropdownClass="border rounded"
                    required
                    enableSearch={true}
                    inputProps={{
                      required: true,
                      autoFocus: false,
                    }}
                  />
                  {errors.phoneNumber && (
                    <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
                  )}
                </div>
                <div className="mb-2">
                  <label className="text-gray-700">Company Name</label>
                  <input
                    id="Companyname"
                    type="text"
                    name="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                  {errors.companyName && (
                    <p className="text-red-500 text-sm">{errors.companyName}</p>
                  )}
                </div>
                <div className="mb-2">
                  <label className=" text-gray-700">Business Email</label>
                  <input
                    id="businessemail"
                    type="email"
                    name="businessemail"
                    value={businessemail}
                    onChange={(e) => setEmailId(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                  {errors.businessemail && (
                    <p className="text-red-500 text-sm">
                      {errors.businessemail}
                    </p>
                  )}
                </div>
 
                <div className="mb-4">
                  <label className=" text-gray-700">
                    Project Details(Optional)
                  </label>
                  <input
                    id="projectdetails"
                    type="text"
                    name="projectdetails"
                    value={projectdetails}
                    onChange={(e) => setprojectdetails(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                  />
                  {errors.companyName && (
                    <p className="text-red-500 text-sm">{errors.companyName}</p>
                  )}
                </div>
                <div className="disclaimerContainercontact">
                  <label className="checkboxLabel">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      className="checkbox"
                    />
                    <span className="checkboxText">
                      I agree and authorize team to contact me and/or send
                      relevant information over Email, SMS & WhatsApp. This will
                      override the registry with DNC/NDNC.
                    </span>
                  </label>
                </div>
                {errors.terms && (
                  <p className="text-red-500 text-sm">{errors.terms}</p>
                )}
                <div className="linksContainer">
                  <a href="/terms-of-service/" className="link">
                    Terms of Service
                  </a>
                  <a href="/privacy-policy/" className="link">
                    Privacy Policy
                  </a>
                </div>
                  </div>
                  <div className="mb-4">
  <button
    id="submit"
    type="submit"
    className={`w-full px-4 py-2 bg-[#C62B6D] text-white rounded ${
      !isChecked || isLoading ? "opacity-50 cursor-not-allowed" : ""
    }`}
    disabled={!isChecked || isLoading}
  >
    {isLoading ? (
      <div className="flex items-center justify-center space-x-2">
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        <span>Submitting...</span>
      </div>
    ) : (
      "Submit"
    )}
  </button>
</div>
              </form>
            </div>
          </section>
        )}
 

        {isSuccessOpen && (
          <section className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
              <a
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeSuccessModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </a>
              <h2 className="text-2xl font-bold mb-4">Success!</h2>
              <p className="mb-4">Your request has been sent successfully.</p>
              <button
                id="close"
                className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                onClick={closeSuccessModal}
              >
                Close
              </button>
            </div>
          </section>
        )}
      </section>
    </>
  );
};
export default Popup;
