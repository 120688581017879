import React, { useEffect } from "react";
import Prism from "prismjs";
import "../pages/style.css";
import "prismjs/themes/prism.css";

const CustomMarkdownRenderer = ({ content, setHeadings }) => {
  const safeContent = typeof content === "string" ? content : "";
  const paddingBottomRegex = /^(.*?)(\^\^\^)(.*?)$/gm;
  const backlinkRegex = /\[(.*?)\]\((.*?)\)/g;
  const customTagRegex = /\$\$(.*?)(\!\[([^\]]+)\]\(([^)]+)\))(.*?)\$\$/gs;
  const headingRegex = /^(#+)\s(.*)/g;
  const listRegex = /^(\*|\d+\.*|>|-|&&|[a-zA-Z]\.)\s(.*)/g;
  const imageRegex = /!\[([^\]]+)\]\(([^)]+)\)/g;
  const boldRegex = /\*\*(.*?)\*\*/g;
  const italicRegex = /_(.*?)_/g;
  const underlineRegex = /__(.*?)__/g;
  const centerRegex = /@@(.*?)@@/g;
  const centerRegex2 = /##(.*?)##/g;
  const centeredItalicRegex = /@_(.*?)_@/g;
  const linkRegex = /\[(.*?)\]\((.*?)\)/g;
  const pinktext = /%%(.*?)%%/g;
  const heading7 = /h7-(.*?)-h7/g;
  const heading9 = /h9-(.*?)-h9/g;
  const codeBlockRegex = /```(.*?)\n([\s\S]*?)```/g;
  const customSymbolRegex = /\/\/\?\s*([\s\S]*?)\s*\/\/\?/gs;
  const indentParagraphRegex = /\|\|\s\|\|(.*?)$/g;
  // Regex to match video markdown-style syntax ![alt text](video_url)
  const videoRegex = /#\[([^\]]+)\]\(([^)]+)\.mp4\)/g;
  const youtubeRegex =
    /#\[([^\]]+)\]\((https?:\/\/(?:www\.)?youtube\.com\/watch\?v=([^\)]+))\)/g;

  const splitTextRegex = /(.*?)!!(.*)/s;

  const headings = [];

  const lines = safeContent.split("\n");

  const parsedContent = lines.map((line, index) => {
    let parsedLine = line;
    parsedLine = parsedLine.replace(backlinkRegex, (match, text, url) => {
      return `<a href="${url}" class="underline text-blue-500 hover:text-blue-700">${text}</a>`;
    });
    parsedLine = parsedLine
      .replace(boldRegex, "<strong>$1</strong>")
      .replace(italicRegex, "<em>$1</em>")
      .replace(underlineRegex, "<u>$1</u>");
    if (paddingBottomRegex.test(line)) {
      parsedLine = line.replace(
        paddingBottomRegex,
        (match, textBefore, paddingTag, textAfter, text) => {
          const escapedText = escapeHtml(text.trim());
          return `
          <div style="padding-bottom:10px;">
            ${textBefore.trim()} ${textAfter.trim()}
          </div>
        `;
        }
      );
    }
    if (customTagRegex.test(line)) {
      parsedLine = line.replace(
        customTagRegex,
        (match, paragraph, imageTag, alt, src, rest) => {
          const paragraphWithLinks = paragraph
            .replace(backlinkRegex, (match, text, url) => {
              return `<a href="${url}" class="underline text-blue-500 hover:text-blue-700">${text}</a>`;
            })

            .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")

            .replace(/_(.*?)_/g, "<em>$1</em>")

            .replace(/__(.*?)__/g, "<u>$1</u>");

          const restWithLinks = rest
            .trim()
            .replace(backlinkRegex, (match, text, url) => {
              return `<a href="${url}" class="underline text-blue-500 hover:text-blue-700">${text}</a>`;
            })

            .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")

            .replace(/_(.*?)_/g, "<em>$1</em>")

            .replace(/__(.*?)__/g, "<u>$1</u>");

          return `
            <div class="flex flex-col lg:flex-row">
              <div class="mb-4 lg:mb-0">${paragraphWithLinks}</div>
              <img class="w-auto lg:block lg:ml-4" src="${src}" alt="${alt}" style="height: auto;" />
              <div class="mt-2">${restWithLinks}</div>
            </div>
          `;
        }
      );
    } else if (headingRegex.test(line)) {
      parsedLine = line.replace(headingRegex, (match, hashes, text) => {
        const level = hashes?.length;
        const id = `heading-${index}`;
        headings.push({ level, text, id });

        const headingSize =
          {
            1: "text-3xl md:text-4xl lg:text-4xl font-semibold",
            2: "text-left text-2xl md:text-3xl lg:text-3xl font-semibold",
            3: "text-left text-xl md:text-xl lg:text-2xl 2xl:text-xl font-semibold",
            4: "text-base md:text-lg lg:text-xl font-semibold",
            5: "text-sm md:text-base lg:text-lg font-semibold",
            6: "text-xs md:text-sm lg:text-base font-semibold",
          }[level] || "text-lg md:text-xl lg:text-2xl font-semibold";

        return `<h${level} id="${id}" class="${headingSize}">${text}</h${level}>`;
      });
    } else if (listRegex.test(line)) {
      let listType = "";

      if (line.startsWith("*")) {
        listType = "square";
      } else if (line.startsWith(">")) {
        listType = "arrow";
      } else if (line.startsWith("&&")) {
        listType = "disc";
      }

      parsedLine = line.replace(listRegex, (match, bullet, text) => {
        let bulletSymbol = bullet;
        if (listType === "decimal") {
          const count = bullet.replace(".", "");
          bulletSymbol = `${count}.`;
        } else if (listType === "lower-alpha") {
          bulletSymbol = `${bullet.toLowerCase()}`;
        } else if (listType === "square") {
          bulletSymbol = `■`;
        } else if (listType === "arrow") {
          bulletSymbol = `➤`;
        } else if (listType === "disc") {
          bulletSymbol = `●`;
        }

        const [boldText, normalText] = text.split("||", 2);
        const formattedText = `<strong>${boldText.trim()}</strong>${
          normalText
            ? " " +
              normalText
                .trim()
                .replace(
                  linkRegex,
                  '<a href="$2" class="underline text-blue-500 hover:text-blue-500">$1</a>'
                )
            : ""
        }`;

        return `
          <li class="custom-list-item ${listType}">
            <span class="bullet 2xl:text-base">${bulletSymbol}</span>
            <span class="text 2xl:text-xl">${formattedText}</span>
          </li>
        `;
      });

      parsedLine = `<ul class="custom-list list-${listType}">${parsedLine}</ul>`;
    } else if (indentParagraphRegex.test(line)) {
      parsedLine = line.replace(
        indentParagraphRegex,
        (match, paragraphText) => {
          return `<div class="ml-6">${paragraphText.trim()}</div>`;
        }
      );
    } else if (pinktext.test(line)) {
      parsedLine = line.replace(pinktext, (match, text) => {
        return `<span class="text-[#C62A6D] text-xl font-bold">${text}</span>`;
      });
    } else if (heading7.test(line)) {
      parsedLine = line.replace(heading7, (match, text) => {
        return `<span class="text-5xl font-semibold">${text}</span>`;
      });
    } else if (heading9.test(line)) {
      parsedLine = line.replace(heading9, (match, text) => {
        return `<span class="text-lg font-semibold">${text}</span>`;
      });
    } else if (codeBlockRegex.test(line)) {
      parsedLine = line.replace(codeBlockRegex, (match, lang, code) => {
        const language = lang || "plaintext";
        const highlightedCode = Prism.highlight(
          code,
          Prism.languages[language],
          language
        );
        return `<pre class="language-${language} custom-code-block"><code class="language-${language}">${highlightedCode}</code></pre>`;
      });
    } else if (imageRegex.test(line)) {
      parsedLine = line.replace(imageRegex, (match, alt, src) => {
        return `
          <div style="display: flex; justify-content: center; align-items: center;">
            <img class="py-[20px]" src="${src}" alt="${alt}" style="height: auto;"/>
          </div>
        `;
      });
    } else if (videoRegex.test(line)) {
      parsedLine = line.replace(videoRegex, (match, alt, src) => {
        return `
          <div style="display: flex; justify-content: center; align-items: center;">
            <video class="py-[20px]" controls  style="max-width: 100%; height: 100%;">
              <source src="${src}" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        `;
      });
    } else if (youtubeRegex.test(line)) {
      parsedLine = line.replace(
        youtubeRegex,
        (match, alt, videoUrl, videoId) => {
          // Generate the YouTube thumbnail URL
          const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

          return `
<div style="position: relative; display: flex; justify-content: center; align-items: center;">
  <a href="${videoUrl}" target="_blank" style="text-decoration: none;">
    <img src="${thumbnailUrl}" alt="${alt}" class="2xl:w-screen lg:w-screen  object-contain" style="max-width: 100%; height: auto; cursor: pointer;">
    <i class="fas fa-play play-icon absolute "></i>
  </a>
</div>
 
<style>
  /* Default (Desktop) Style */
  .play-icon {
    position: absolute;
    top: 600px;
    right: 700px;
    font-size: 80px;
    color: white;
    text-align: center;
  }
 
  /* Tablet View */
  @media (max-width: 1024px) {
    .play-icon {
      right: 10px;
      top:200px; /* Adjust for tablet */
      font-size: 50px; /* Smaller icon size for tablet */
    }
  }
 @media (max-width: 2000px) {
    .play-icon {
      right: 330px;
      top: 150px;
      font-size: 60px;
    }
 
 
  /* Mobile View */
  @media (max-width: 768px) {
    .play-icon {
      right: 170px;
      top:100px; /* Adjust for mobile */
      font-size: 40px; /* Smaller icon size for mobile */
    }
  }
</style>
 
 
 
    `;
        }
      );
    } else if (customSymbolRegex.test(line)) {
      parsedLine = line.replace(customSymbolRegex, (match, text) => {
        // Split the text by '!!' or '::' and trim each part
        const splitParts = text
          .trim()
          .split(/!!|::/)
          .map((part) => part.trim())
          ?.filter((part) => part !== "");

        // If there are split parts, wrap them in the custom-symbol div
        if (splitParts?.length > 0) {
          return `
            <div class="custom-symbol fixed-container">
              ${splitParts
                .map((part, index) => {
                  // Apply bold and italic replacements
                  const formattedPart = part.replace(
                    boldRegex,
                    "<strong>$1</strong>"
                  ); // Replace **text** with <strong>text</strong>
                  // Replace _text_ with <em>text</em>

                  // Add margin between paragraphs using 'mb-2' if :: is detected
                  const marginClass = index === 0 ? "" : "mb-2"; // Apply mb-2 for paragraphs after the first
                  const additionalMarginClass = index === 0 ? "" : "ml-20"; // Only add margin-left for subsequent parts

                  return `<div class="paragraph ${marginClass} ${additionalMarginClass}">${formattedPart}</div>`; // Add a class for styling
                })
                .join("")}
            </div>
          `;
        }

        // If no split parts, return the text as a single span with formatting
        const formattedText = text.replace(boldRegex, "<strong>$1</strong>");

        return `<span class="custom-symbol">${formattedText}</span>`;
      });
    } else if (centerRegex.test(line)) {
      parsedLine = line.replace(centerRegex, (match, content) => {
        return `<div class="text-center">${content}</div>`;
      });
    } else if (centerRegex2.test(line)) {
      parsedLine = line.replace(centerRegex2, (match, content) => {
        return `<div class="text-center italic">${content}</div>`;
      });
    } else if (centeredItalicRegex.test(line)) {
      parsedLine = line.replace(centeredItalicRegex, (match, content) => {
        return `<div class="text-center italic">${content}</div>`;
      });
    } else if (splitTextRegex.test(line)) {
      parsedLine = line.replace(
        splitTextRegex,
        (match, firstPart, secondPart) => {
          return `
          <div>
            <div>${firstPart.trim()}</div>
            <div class="py-[5px] ">${secondPart.trim()}</div>
          </div>
        `;
        }
      );
    }
    {
      parsedLine = parsedLine
        .replace(boldRegex, "<strong>$1</strong>")
        // .replace(italicRegex, "<em>$1</em>")
        .replace(underlineRegex, "<u>$1</u>");
      parsedLine = `<p class="py-[5px] ">${parsedLine}</p>`;
    }

    return <div key={index} dangerouslySetInnerHTML={{ __html: parsedLine }} />;
  });

  useEffect(() => {
    setHeadings(headings);
    Prism.highlightAll();
  }, [headings, setHeadings]);

  const escapeHtml = (text) => {
    return text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  };
  return (
    <div className="custom-markdown-container">
      <style>{`
        .custom-list {
          padding-left: 0;
          list-style-type: none;
        }
        .custom-list-item {
          display: flex;
          align-items: flex-start;
          margin-bottom: 0.25em; /* Reduce the margin between list items */
          font-size: 0.875rem; /* Reduce font size for list items */
        }
        .custom-list-item .bullet {
          flex-shrink: 0;
          width: 15px; /* Reduce the width of the bullet */
          text-align: center;
          margin-right: 10px; /* Adjust spacing between bullet and text */
        }
        .custom-list-item .text {
          line-height: 1.5; /* Improve line height */
        }
        .custom-code-block {
          background: #f5f5f5;
          border-radius: 4px;
          padding: 1em;
          overflow-x: auto;
          white-space: pre-wrap;
        }
.custom-symbol {
  background-color: #f3f4f6; /* gray-100 */
  border-left: 4px solid #C62A6D; /* pink */
  padding: 10px;
  margin: 10px 0;
  display: inline-block;
  border-radius: 0 8px 8px 0; /* Curved right corners only */
  white-space: pre-wrap;
}
 
.fixed-container {
  width: 700px; /* Set a fixed width */
 
  overflow-x: auto; /* Enable horizontal scrolling if content overflows horizontally */
  overflow-y: hidden; /* Prevent vertical scrolling */
  white-space: nowrap;
 
}
  @media (max-width: 1024px) {
  .fixed-container {
    width: 500px;
  }
}
  @media (max-width: 768px) {
  .fixed-container {
    width:340px;
   
  }
}
  .custom-symbol .paragraph {
  padding-bottom: 5px;
    min-width: 200px; /* Minimum width for paragraphs to ensure scrolling works */
  white-space: nowrap; /* Adjust the value to set your desired gap */
}
 
.mb-2 {
  margin-bottom: 0px; /* Add a 10px gap between paragraphs */
}
 
.ml-20 {
  margin-left: 52px; /* Additional left margin for split parts */
}
 
      `}</style>
      {parsedContent}
    </div>
  );
};

export default CustomMarkdownRenderer;
