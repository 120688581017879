import React from "react";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";

import CustomMarkdownRenderer from "../components/Markdown.js";
import bg1 from "../assets/Webinar/bg1.webp";
import { NavLink } from "react-router-dom";
import calendarIcon from "../assets/Webinar/calender.webp";
import timeIcon from "../assets/Webinar/time.webp";
import { Helmet } from "react-helmet";

import Popup from "../components/popup.js";
import { Heading2 } from "lucide-react";
import useFetch from "../hooks/useFetch.js";

import Lottie from "react-lottie";
import loading from "../pages/loading.json";

const Webinar = () => {
  const {
    loading: webinarsLoading,
    data: webinars,
    error: webinarsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/webinar/data.json`
  );

  const {
    loading: pastwebinarsLoading,
    data: pastwebinars,
    error: pastwebinarsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/pastwebinar/data.json`
  );

  // Lottie animation options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  console.log("WEBINARS", webinars);
  const webinarData = webinars?.[0];
  const pastwebinarData = pastwebinars || [];

  const youtubeUrl = (pastwebinar) => pastwebinar?.videourl || "";
  const youtubeDesc = (pastwebinar) => pastwebinar?.youtube_desc || "";

  const getYouTubeVideoId = (url) => {
    if (typeof url !== "string") {
      return null;
    }
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2]?.length === 11 ? match[2] : null;
  };

  const icons = webinarData?.icons || [];
  const webinarHeading = webinarData?.upcoming_details || "Default Heading";
  const takeaway_desc = webinarData?.keytakaway_desc || "Default Heading";
  const calender = webinarData?.calender || "Default Date";
  const time = webinarData?.time || "Default Time";
  const reglink = webinarData?.reg_link || "Default Time";
  const images = webinarData?.upcoming_images || [];

  const [shouldRender, setShouldRender] = useState(true);

  useEffect(() => {
    const calenderDate = webinarData?.calender
      ? new Date(webinarData.calender)
      : null;
    const currentDate = new Date();

    const stripTime = (date) =>
      new Date(date.getFullYear(), date.getMonth(), date.getDate());

    const strippedCalenderDate = calenderDate ? stripTime(calenderDate) : null;
    const strippedCurrentDate = stripTime(currentDate);

    console.log("Calendar Date:", strippedCalenderDate);
    console.log("Current Date:", strippedCurrentDate);

    if (strippedCalenderDate) {
      const shouldShow = strippedCurrentDate <= strippedCalenderDate;
      console.log("Should show webinar:", shouldShow);
      setShouldRender(shouldShow);
    } else {
      console.log("No valid calendar date found");
      setShouldRender(false);
    }
  }, [webinarData]);

  const speakerDesc1 = webinarData?.speaker_desc1 || "Default Speaker Desc 1";
  const speakerDesc2 = webinarData?.speaker_desc2 || "Default Speaker Desc 2";
  const speakerDesc3 = webinarData?.speaker_desc3 || "Default Speaker Desc 3";
  const speakerDesc4 = webinarData?.speaker_desc4 || "Default Speaker Desc 4";

  const takeaways = [
    webinarData?.takeaway1 || "Default Takeaway 1",
    webinarData?.takeaway2 || "Default Takeaway 2",
    webinarData?.takeaway3 || "Default Takeaway 3",
    webinarData?.takeaway4 || "Default Takeaway 4",
    webinarData?.takeaway5 || "Default Takeaway 5",
    webinarData?.takeaway6 || "Default Takeaway 6",
  ];

  const setHeadings = () => {};

  const renderImages = () => {
    const imageCount = images?.length;

    // Dummy setHeadings function to avoid errors
    const setHeadings = () => {};

    if (imageCount === 1) {
      const backgroundColor = webinarData?.onespeaker_color1 || "#FFFFFF"; // Default to white if not defined

      return (
        <>
          <section className="flex max-md:hidden flex-col justify-center items-center h-full relative">
            <div className="z-0 absolute inset-0 left-0 top-0 flex items-center justify-center">
              <div className="md:w-56 md:h-56 lg:w-56 md:-mt-28 2xl:-mt-24 lg:h-56 bg-gradient-to-t from-[#D2A4E1] to-[#ECCEF1] rounded-full absolute opacity-100"></div>
              <div className="w-28 h-28 border-2 border-white 2xl:left-10 2xl:top-0 md:-top-3 lg:top-4 left-0 translate-x-20 rounded-l-none rounded-tl-3xl rounded-tr-3xl absolute rounded-lg"></div>
            </div>

            <figure
              className="flex z-10 flex-col items-center justify-center relative"
              style={{
                backgroundColor: backgroundColor, // Use the color1 value here
              }}
            >
              <img
                src={`${images[0].url}`}
                alt={`Webinar Image 1`}
                className="object-cover rounded-lg"
              />
            </figure>

            <p className="mt-2 text-xs z-10">
              <CustomMarkdownRenderer
                content={speakerDesc1}
                setHeadings={setHeadings}
              />
            </p>
          </section>

          <article className="flex   md:hidden  flex-col justify-center items-center h-full relative">
            <div className="z-0 pl-20 absolute -mt-10 inset-0 left-0 top-0 flex items-center justify-center">
              <div className="w-52 h-52 bg-gradient-to-t from-[#D2A4E1] to-[#ECCEF1] rounded-full absolute opacity-100"></div>
            </div>

            <figure
              className="flex mt-10 z-10 ml-20 rounded-lg  flex-col items-center justify-center relative"
              style={{
                backgroundColor: backgroundColor,
              }}
            >
              <img
                src={`${images[0].url}`}
                alt={`Webinar Image 1`}
                className="object-cover  rounded-lg"
              />
            </figure>

            <p className="mt-2 text-xs ml-28 translate-x-5 z-10">
              <CustomMarkdownRenderer
                content={speakerDesc1}
                setHeadings={setHeadings}
              />
            </p>
          </article>
        </>
      );
    }

    if (imageCount === 2) {
      // Extract colors and ensure they are defined

      const backgroundColor1 = webinarData?.twospeaker_color1 || "#FFFFFF"; // Default to white if not defined
      const backgroundColor2 = webinarData?.twospeaker_color2 || "#FFFFFF"; // Default to white if not defined

      console.log("Background Color 1:", backgroundColor1);
      console.log("Background Color 2:", backgroundColor2);

      return (
        <>
          {/* Desktop View */}

          <section className="flex max-md:hidden flex-col md:gap-28 lg:gap-24 items-center -mt-10">
            {images.map((image, index) => (
              <div
                key={index}
                className="relative flex flex-col w-auto items-center justify-center"
              >
                {/* Circle Background */}
                <figure className="absolute inset-0 flex items-center justify-center z-0">
                  <div
                    className={`w-60 h-60 rounded-full absolute opacity-100 ${
                      index === 1
                        ? `bg-gradient-to-t from-[#B8B5EB] to-[#D1E1F5]`
                        : `bg-gradient-to-t from-[#D2A4E1] to-[#ECCEF1]`
                    }`}
                  ></div>
                  <div
                    className="w-28 h-28 border-2 border-white -top-3 md:left-32 lg:left-32 -translate-x-3 rounded-l-none rounded-tl-3xl rounded-tr-3xl absolute z-10"

                    /* White square on top of the circle */
                    /* Center the square */
                  ></div>
                </figure>

                {/* Image */}
                <figure
                  className="relative translate-y-10 flex rounded-lg items-center justify-center"
                  style={{
                    backgroundColor:
                      index === 0 ? backgroundColor1 : backgroundColor2, // Apply colors based on index
                  }}
                >
                  <img
                    src={`${image.url}`}
                    alt={`Webinar Image ${index + 1}`}
                    className="object-cover rounded-lg"
                  />
                </figure>

                {/* Speaker Description */}
                {index === 0 && (
                  <div className="mt-2 text-xs lg:translate-y-10 md:translate-y-10 z-10">
                    <CustomMarkdownRenderer
                      content={speakerDesc1}
                      setHeadings={setHeadings}
                    />
                  </div>
                )}
                {index === 1 && (
                  <div className="mt-2 text-xs lg:translate-y-10 md:translate-y-10  z-10">
                    <CustomMarkdownRenderer
                      content={speakerDesc2}
                      setHeadings={setHeadings}
                    />
                  </div>
                )}
              </div>
            ))}
          </section>

          {/* Mobile View */}

          <section className="flex md:hidden pl-20 flex-col gap-12 items-center pt-0">
            {images.map((image, index) => (
              <article
                key={index}
                className="relative flex  flex-col w-auto items-center justify-center"
              >
                {/* Circle Background */}
                <div className="absolute  flex items-center justify-center z-0">
                  <div
                    className={`w-56 -mt-10 h-56 rounded-full absolute opacity-100 ${
                      index === 1
                        ? `bg-gradient-to-t from-[#B8B5EB] to-[#D1E1F5]`
                        : `bg-gradient-to-t from-[#D2A4E1] to-[#ECCEF1]`
                    }`}
                  ></div>
                </div>

                {/* Image */}
                <div
                  className="relative mt-10 ml-0 rounded-lg  flex items-center justify-center"
                  style={{
                    backgroundColor:
                      index === 0 ? backgroundColor1 : backgroundColor2, // Apply colors based on index
                  }}
                >
                  <img
                    src={`${image.url}`}
                    alt={`Webinar Image ${index + 1}`}
                    className="object-cover   rounded-lg"
                  />
                </div>

                {/* Speaker Description */}
                {index === 0 && (
                  <div className="mt-5 ml-10  text-xs z-10">
                    <CustomMarkdownRenderer
                      content={speakerDesc1}
                      setHeadings={setHeadings}
                    />
                  </div>
                )}
                {index === 1 && (
                  <div className="mt-5 ml-10  text-xs z-10">
                    <CustomMarkdownRenderer
                      content={speakerDesc2}
                      setHeadings={setHeadings}
                    />
                  </div>
                )}
              </article>
            ))}
          </section>
        </>
      );
    }

    if (imageCount === 3) {
      const backgroundColor1 = webinarData?.threespeaker_color1 || "#FFFFFF"; // Default to white if not defined
      const backgroundColor2 = webinarData?.threespeaker_color2 || "#FFFFFF"; // Default to white if not defined
      const backgroundColor3 = webinarData?.threespeaker_color3 || "#FFFFFF"; // Default to white if not defined

      return (
        <>
          <section className=" max-md:hidden flex gap-5 justify-center min-h-full">
            {/* Container for the left image */}
            <article className="flex flex-col items-center mr-4 2xl:pr-10 relative min-h-full">
              {images[0] && (
                <>
                  {/* Background behind the left image */}
                  <div className="absolute inset-0 flex items-center justify-center z-0">
                    <div className=" md:w-48 md:h-48 lg:w-72 lg:h-72 2xl:right-10 top-32 right-0 bg-gradient-to-l from-[#D2A4E1] to-[#ECCEF1] rounded-full absolute opacity-50 z-0"></div>
                    <div className="w-40 h-40 border-2 border-white md:top-10 lg:top-16 left-0  2xl:left-24 -translate-x-3 rounded-l-none rounded-tl-3xl rounded-tr-3xl absolute z-10"></div>
                  </div>

                  {/* Image */}
                  <figure className="relative  flex flex-col py-20 min-h-full items-center ">
                    <div
                      className={`relative flex flex-col items-end justify-end z-10 rounded-tl-3xl rounded-tr-3xl rounded-br-3xl pt-20`}
                      style={{ backgroundColor: backgroundColor1 }}
                    >
                      <img
                        src={`${images[0].url}`}
                        alt={`Webinar Image 1`}
                        className={` h-fit rounded-br-3xl`}
                      />
                    </div>
                    {/* Speaker description 1 below the left image */}

                    <div className="mt-2 text-xs  z-10 ">
                      <CustomMarkdownRenderer
                        content={speakerDesc1}
                        setHeadings={setHeadings}
                      />
                    </div>
                  </figure>
                </>
              )}
            </article>

            {/* Container for the two images on the right */}

            <article className="flex flex-col items-center">
              {images?.slice(1, 3).map((image, index) => (
                <div
                  key={index}
                  className="relative ml-1 pb-10 flex flex-col items-center mb-4"
                >
                  {/* Background behind each image */}
                  <figure className="absolute inset-0 flex items-center justify-center z-0">
                    <div
                      className={`${
                        index === 0
                          ? " md:w-44 md:h-44 lg:w-52 lg:h-52 2xl:left-20  md:-top-0 lg:-top-12 lg:-left-10 absolute bg-gradient-to-r from-[#B8B5EB] to-[#D1E1F5] opacity-100"
                          : "md:w-44 md:h-44 lg:w-52 lg:h-52 md:-top-0 lg:-top-12 lg:left-20 absolute bg-gradient-to-l from-[#D2A4E1] to-[#ECCEF1] opacity-100"
                      } rounded-full absolute opacity-50 z-0`}
                    ></div>
                    <div className="md:w-28 md:h-28 border-2 border-white 2xl:right-24  md:-top-3 md:right-0 md:translate-x-2 rounded-l-none rounded-tl-3xl rounded-tr-3xl absolute z-10"></div>
                  </figure>

                  {/* Image */}
                  <figure
                    className={`relative flex w-auto h-auto rounded-tl-3xl rounded-tr-3xl rounded-br-3xl items-center justify-center mb-2 z-10`}
                    style={{
                      backgroundColor:
                        index === 0 ? backgroundColor2 : backgroundColor3,
                    }}
                  >
                    <img
                      src={`${image.url}`}
                      alt={`Webinar Image ${index + 2}`}
                      className={`object-cover w-auto h-auto rounded-br-3xl`}
                    />
                  </figure>

                  {/* Speaker descriptions below the images on the right */}

                  <p className="text-xs text-left z-10">
                    <CustomMarkdownRenderer
                      content={index === 0 ? speakerDesc2 : speakerDesc3}
                      setHeadings={setHeadings}
                    />
                  </p>
                </div>
              ))}
            </article>
          </section>

          {/* Mobile View */}

          <section className="flex md:hidden -mt-20  flex-col gap-10 items-center justify-center ">
            {/* Container for the first image */}
            <article className="flex flex-col items-center relative ">
              {images[0] && (
                <>
                  {/* Background behind the first image */}
                  <div className="">
                    <div className="w-52 h-52   md:w-48 md:h-48 lg:w-72 lg:h-72 top-20 right-10 translate-x-5 bg-gradient-to-l from-[#D2A4E1] to-[#ECCEF1] rounded-full absolute opacity-50 z-0"></div>
                  </div>

                  {/* First Image */}
                  <figure className="relative flex flex-col p-5 items-center">
                    <div className="relative pl-10 flex flex-col items-end justify-end z-10 rounded-tl-3xl rounded-tr-3xl rounded-br-3xl pt-20">
                      <img
                        src={`${images[0].url}`}
                        alt="Webinar Image 1"
                        className="h-fit bg-[#FFE897] rounded-xl rounded-br-3xl"
                      />
                    </div>
                    {/* Speaker description 1 below the first image */}
                    <div className="mt-2 translate-x-6 ml-20 text-xs z-10">
                      <CustomMarkdownRenderer
                        content={speakerDesc1}
                        setHeadings={setHeadings}
                      />
                    </div>
                  </figure>
                </>
              )}
            </article>

            {/* Container for the second and third images */}
            {images?.slice(1, 3).map((image, index) => (
              <article
                key={index}
                className="flex   flex-col items-center p-5 relative "
              >
                {/* Background behind each image */}
                <figure className="">
                  <div
                    className={`${
                      index === 0
                        ? "w-52 h-52  md:w-44 md:h-44 lg:w-52 lg:h-52 translate-x-3 right-10 md:-top-0 lg:-top-12 lg:-left-10 absolute bg-gradient-to-r from-[#B8B5EB] to-[#D1E1F5] opacity-100"
                        : "w-52 h-52 md:w-44 md:h-44 lg:w-52 right-10 translate-x-5 lg:h-52 md:-top-0 lg:-top-12 lg:left-20 absolute bg-gradient-to-l from-[#D2A4E1] to-[#ECCEF1] opacity-100"
                    } rounded-full absolute opacity-50 z-0`}
                  ></div>
                </figure>

                {/* Image */}
                <div
                  className="relative flex translate-y-5  translate-x-5 w-auto h-auto rounded-tl-3xl rounded-tr-3xl rounded-br-3xl items-center justify-center mb-2 z-10"
                  style={{
                    backgroundColor:
                      index === 0 ? backgroundColor2 : backgroundColor3,
                  }}
                >
                  <img
                    src={`${image.url}`}
                    alt={`Webinar Image ${index + 2}`}
                    className="object-cover   w-auto h-auto rounded-br-3xl"
                  />
                </div>

                {/* Speaker descriptions below each image */}

                <p className="text-xs translate-y-5    ml-16 translate-x-6 mt-2 z-10">
                  <CustomMarkdownRenderer
                    content={index === 0 ? speakerDesc2 : speakerDesc3}
                    setHeadings={setHeadings}
                  />
                </p>
              </article>
            ))}
          </section>
        </>
      );
    }

    if (imageCount === 4) {
      const backgroundColor1 = webinarData?.fourspeaker_color1 || "#FFFFFF"; // Default to white if not defined
      const backgroundColor2 = webinarData?.fourspeaker_color2 || "#FFFFFF"; // Default to white if not defined
      const backgroundColor3 = webinarData?.fourspeaker_color3 || "#FFFFFF"; // Default to white if not defined
      const backgroundColor4 = webinarData?.fourspeaker_color4 || "#FFFFFF"; // Default to white if not defined

      // Array to hold background colors for each image
      const backgroundColors = [
        backgroundColor1,
        backgroundColor2,
        backgroundColor3,
        backgroundColor4,
      ];

      return (
        <section className="grid grid-cols-1 md:grid-cols-2 md:gap-20 lg:gap-20 gap-16 pl-0 pt-10 md:w-auto">
          {images.map((image, index) => (
            <div
              key={index}
              className="relative flex flex-col items-center w-1/2 md:w-auto mx-auto md:mx-0" // Takes 50% width on mobile
            >
              {/* Circle Background */}
              <figure className="absolute -translate-y-0 md:-translate-y-16 lg:-translate-y-0 inset-0 flex items-center justify-center z-0">
                <figure
                  className={`w-52 h-52 -mt-20 md:-mt-0 lg:w-60 lg:h-60 rounded-full absolute opacity-100 ${
                    index === 0
                      ? "md:w-40 md:h-40 lg:w-60 lg:h-56 lg:top-0 lg:right-0 2xl:right-20 absolute bg-gradient-to-t from-[#B8B5EB] to-[#D1E1F5]"
                      : index === 1
                      ? "md:w-40 md:h-40 lg:w-60 lg:h-56 lg:top-0 lg:left-0 2xl:left-20 absolute bg-gradient-to-t from-[#D2A4E1] to-[#ECCEF1]"
                      : index === 2
                      ? "md:w-40 md:h-40 lg:w-60 lg:h-56 lg:top-0 lg:right-0 2xl:right-20 absolute bg-gradient-to-t from-[#B8B5EB] to-[#D1E1F5]"
                      : "md:w-40 md:h-40 lg:w-60 lg:h-56 lg:top-0 lg:left-0 2xl:left-20 absolute bg-gradient-to-t from-[#D2A4E1] to-[#ECCEF1]"
                  }`}
                ></figure>

                <div
                  className={`absolute border-2 border-white z-10 ${
                    index === 0
                      ? "md:w-24 md:h-20 lg:w-28 lg:h-28 md:top-12 lg:-top-3 md:-left-4 lg:-left-3 2xl:-left-0 2xl:translate-x-16 rounded-l-none rounded-tl-3xl rounded-tr-3xl"
                      : index === 1
                      ? "md:w-24 md:h-20 lg:w-28 lg:h-28 md:top-12 lg:-top-3 lg:-right-3 md:-right-2 2xl:-right-0 2xl:-translate-x-16 rounded-l-none rounded-tl-3xl rounded-tr-3xl"
                      : index === 2
                      ? "md:w-24 md:h-20 lg:w-28 lg:h-28 md:top-12 lg:-top-3 md:-left-4 lg:-left-3 2xl:-left-0 2xl:translate-x-16 rounded-l-none rounded-tl-3xl rounded-tr-3xl"
                      : "md:w-24 md:h-20 lg:w-28 lg:h-28 md:top-12 lg:-top-3 lg:-right-3 md:-right-2 2xl:-right-0 2xl:-translate-x-16 rounded-l-none rounded-tl-3xl rounded-tr-3xl"
                  }`}
                ></div>
              </figure>

              {/* Image Container */}
              <figure
                className="relative  rounded-lg z-10"
                style={{
                  backgroundColor: backgroundColors[index], // Apply respective background color
                }}
              >
                <img
                  src={`${image.url}`}
                  alt={`Webinar Image ${index + 1}`}
                  className="object-cover rounded-lg"
                />
              </figure>

              {/* Speaker Description */}
              {index === 0 && (
                <div className="mt-2 text-xs lg:text-left z-10">
                  <CustomMarkdownRenderer
                    content={speakerDesc1}
                    setHeadings={setHeadings}
                  />
                </div>
              )}
              {index === 1 && (
                <div className="mt-2 text-xs lg:text-left z-10">
                  <CustomMarkdownRenderer
                    content={speakerDesc2}
                    setHeadings={setHeadings}
                  />
                </div>
              )}
              {index === 2 && (
                <div className="mt-2 text-xs lg:text-left z-10">
                  <CustomMarkdownRenderer
                    content={speakerDesc3}
                    setHeadings={setHeadings}
                  />
                </div>
              )}
              {index === 3 && (
                <div className="mt-2 text-xs lg:text-left z-10">
                  <CustomMarkdownRenderer
                    content={speakerDesc4}
                    setHeadings={setHeadings}
                  />
                </div>
              )}
            </div>
          ))}
        </section>
      );
    }

    return null;
  };

  if (webinarsLoading) {
    return (
      <div className="w-full flex justify-center items-center min-h-screen">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }

  if (webinarsError) return <div>Error loading webinars!</div>;
  if (!webinars) return null;

  if (pastwebinarsLoading) {
    return (
      <div className="w-full flex justify-center items-center min-h-screen">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }

  if (pastwebinarsError) return <div>Error loading pastwebinars!</div>;
  if (!pastwebinars) return null;
  return (
    <section className="font-display text-[#666666]">
      <Helmet>
        <title>Random Walk AI Training: Powering Tomorrow's Solutions</title>
        <meta name="description" content="Explore Webinars" />
        <link rel="canonical" href="https://randomwalk.ai/webinars/" />
      </Helmet>
      {shouldRender && (
        <section
          className="md:pb-[10px] pb-10 2xl:pb-[1000px] lg:pb-[50px] 2xl:pt-[500px] bg-cover bg-no-repeat 2xl:h-[35rem] flex flex-col lg:flex-col justify-start items-start lg:w-auto"
          style={{ backgroundImage: `url(${bg1})` }}
        >
          <article className="w-full h-full flex items-center">
            <div className="md:w-full flex flex-col md:flex-row pt-24 md:pt-32 lg:pt-32 2xl:px-20 md:pr-10 md:pl-5 lg:px-20 md:justify-between">
              <div className="md:w-1/2 2xl:scale-125 pl-5 2xl:pl-24 md:pt-10 2xl:pt-0 ">
                <h3 className="font-display 2xl:pt-16  md:pb-5">
                  <CustomMarkdownRenderer
                    content={webinarHeading}
                    setHeadings={setHeadings}
                  />
                </h3>
                <figure className="flex md:pr-8 items-center md:items-start md:flex-row md:space-x-0">
                  <img
                    src={calendarIcon}
                    alt="Calendar"
                    className="md:pt-[0.4rem] md:mr-2"
                  />
                  <div className="md:text-sm flex justify-center lg:ml-4 font-medium mb-4 pt-5 md:pt-0 md:ml-1 ml-3">
                    <CustomMarkdownRenderer
                      content={calender}
                      setHeadings={setHeadings}
                    />
                  </div>
                </figure>
                <article className="flex -translate-y-3 md:-translate-y-0 md:ml-0 items-center md:items-start md:flex-row md:space-x-0">
                  <img
                    src={timeIcon}
                    alt="Time"
                    className="md:pt-[0.5rem] mr:mr-2"
                  />
                  <div className="flex justify-center lg:ml-4">
                    <div className="md:text-sm font-medium mb-4 pt-5  md:pt-0 md:ml-2 ml-3 ">
                      <CustomMarkdownRenderer
                        content={time}
                        setHeadings={setHeadings}
                      />
                    </div>
                  </div>
                </article>

                <div className="flex justify-left">
                  <a href={reglink} target="_blank" rel="noopener noreferrer">
                    <button className="md:text-sm rounded-lg bg-gradient-to-t from-[#5A59F6] to-[#E9389E] hover:bg-[#2A0677] text-white py-2 px-4 md:py-3 md:px-10">
                      Register Now
                    </button>
                  </a>
                </div>
              </div>
              <div className="md:w-1/2 md:-translate-x-0 md:pl-5 md:px-0 pt-10 md:pt-10 pb-10 lg:pt-5 flex -translate-x-20 md:justify-center">
                {renderImages()}
              </div>
            </div>
          </article>

          <section className="px-5 2xl:py-96 md:py-20 lg:py-0">
            <div className="flex-col items-start md:px-20">
              <h2 className="text-left text-2xl md:text-3xl font-bold">
                Key Takeaways
              </h2>
              <p className="font-display 2xl:pt-0 md:max-w-md 2xl:max-w-2xl md:text-sm 2xl:text-xl md:pt-3">
                <CustomMarkdownRenderer
                  content={takeaway_desc}
                  setHeadings={setHeadings}
                />
              </p>
            </div>
            <article className="grid grid-cols-1 pt-10 md:px-20 md:pt-16 md:grid-cols-3 gap-16">
              {Array.from({ length: 6 }).map((_, index) => (
                <div
                  key={index}
                  className="relative bg-white w-full border rounded-lg p-5 shadow-md"
                >
                  <figure className="absolute -top-6 left-4 bg-[#C8DAFF] rounded-lg px-4 py-4">
                    <img
                      src={`${icons[index]?.url || "/path_to_default_image"}`}
                      alt={`Image ${index + 1}`}
                      className="object-cover"
                    />
                  </figure>
                  <div className="mt-5 text-left md:text-xs lg:text-base">
                    {/* Render rich text here */}
                    <CustomMarkdownRenderer
                      content={takeaways[index]}
                      setHeadings={setHeadings}
                    />
                  </div>
                </div>
              ))}
            </article>
          </section>
        </section>
      )}

      <section className="md:pl-20 pt-20 md:pt-16 2xl:px-40 md:pb-0 lg:pb-5">
        <article className="pl-5 py-5 md:py-0 md:pl-0">
          <h2 className="md:text-4xl md:pt-10 text-3xl pb-5 font-bold text-left">
            Past Webinars
          </h2>

          {pastwebinarData
            ?.sort((a, b) => new Date(b.calender) - new Date(a.calender)) // Sort by date, most recent first
            .map((pastwebinar, index) => {
              const videoId = getYouTubeVideoId(youtubeUrl(pastwebinar));
              const fullImageUrl = pastwebinar?.youtube_img?.[0]?.url
                ? `${pastwebinar?.youtube_img?.[0]?.url}`
                : null;

              const pastimages = pastwebinar?.pastimages || [];
              const pastspeakerDescs = [
                pastwebinar?.pastspeaker_desc1 || "Default Speaker Desc 1",
                pastwebinar?.pastspeaker_desc2 || "Default Speaker Desc 2",
                pastwebinar?.pastspeaker_desc3 || "Default Speaker Desc 3",
                pastwebinar?.pastspeaker_desc4 || "Default Speaker Desc 4",
              ];

              // Fetch and format the 'calender' date
              const calender = pastwebinar?.calender || null;
              const formattedCalenderDate = calender
                ? new Date(calender).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : "Date not available";

              return (
                <section key={index} className="mb-20">
                  <figure className="flex gap-3 md:gap-1 items-center md:items-start md:flex-row md:space-x-0">
                    <img
                      src={calendarIcon}
                      alt="Calendar"
                      className="md:pt-[0.4rem] md:mr-2 "
                    />
                    <div className="text-sm md:text-base mt-1 md:mt-0 lg:text-lg text-gray-600">
                      {formattedCalenderDate}
                    </div>
                  </figure>

                  <div className="relative">
                    <div className="absolute inset-0 flex z-0">
                      <div className="w-36 h-36 lg:left-0 lg:top-0 md:-translate-x-12 bg-[#FEF5D4] rounded-full opacity-100"></div>
                    </div>

                    <p className="relative z-10 font-display md:text-sm 2xl:text-xl md:pb-5 mt-5">
                      <CustomMarkdownRenderer
                        content={pastwebinar?.past_details || "Default Heading"}
                        setHeadings={setHeadings}
                      />
                    </p>

                    <div className="absolute z-10 flex items-center">
                      <div className="w-10 h-[3px] mt-1 bg-[#FFE897] mr-4"></div>
                      <div className="font-normal text-base italic text-[#666666]">
                        Speakers
                      </div>
                    </div>
                  </div>

                  <article className="flex flex-col md:flex-row px-5 md:px-0 md:pb-10 lg:pb-0 md:gap-10 lg:gap-0 2xl:gap-40">
                    {/* Grid Section */}
                    <article
                      className={`grid ${
                        pastimages?.length === 3
                          ? "grid-cols-2 grid-rows-2"
                          : pastimages?.length >= 2
                          ? "grid-cols-2"
                          : "grid-cols-1"
                      } md:pl-0 pb-5 gap-10 mt-20 flex-1`}
                    >
                      {pastimages.map((image, imgIndex) => (
                        <figure key={imgIndex}>
                          <img
                            src={`${image.url}`}
                            alt={`Past Webinar Image ${imgIndex + 1}`}
                            className="object-cover rounded-lg"
                          />
                          <p className="mt-2 md:text-xs text-xs z-10">
                            <CustomMarkdownRenderer
                              content={pastspeakerDescs[imgIndex]}
                              setHeadings={setHeadings}
                            />
                          </p>
                        </figure>
                      ))}
                    </article>

                    {/* YouTube Section */}
                    <article className="flex flex-col items-center md:mt-10 pb-8 md:pb-8 lg:mt-0 md:h-fit mb-10 md:mb-0 2xl:mb-10 md:mx-10 bg-[#FEF5D4] rounded-lg px-4 relative flex-1">
                      {youtubeUrl(pastwebinar) ? (
                        videoId ? (
                          <div className="flex flex-col items-center relative">
                            <a
                              href={youtubeUrl(pastwebinar)}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="relative"
                            >
                              <img
                                src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
                                alt="YouTube Thumbnail"
                                className="w-full md:pt-5 h-auto object-cover shadow-2xl"
                                style={{ cursor: "pointer" }}
                              />
                              <figure className="absolute inset-0 flex items-center justify-center">
                                <svg
                                  className="w-16 h-16 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="white"
                                    strokeWidth="2"
                                    fill="transparent"
                                  />
                                  <path d="M10 8.27L15 12 10 15.73V8.27z" />
                                </svg>
                              </figure>
                            </a>
                            {youtubeDesc(pastwebinar) ? (
                              <div className="md:text-sm lg:text-base text-xs text-left px-2 py-5">
                                {youtubeDesc(pastwebinar)}
                              </div>
                            ) : (
                              <p className="mt-4 text-center text-red-500">
                                No YouTube description available
                              </p>
                            )}
                          </div>
                        ) : (
                          <p>Invalid YouTube URL</p>
                        )
                      ) : (
                        <p>No YouTube URL or image provided</p>
                      )}
                    </article>
                  </article>
                </section>
              );
            })}
        </article>
      </section>

      <div className=" mt-0 lg:mt-10">
        <Popup utmContent={"webinars"} />
      </div>
    </section>
  );
};

export default Webinar;
